define([], function() {
  return {
    __Action: "Aktivitet",
    __Actions: "Aktiviteter",
    __Actual: "Utfall",
    __Ambition: "Ambition",
    __Ambitions: "Ambitioner",
    __Capabilities: "Förmågor",
    __Capability: "Förmåga",
    __ConfidenceHigh: "(3) Hög",
    __ConfidenceLow: "(1) Låg",
    __ConfidenceMedium: "(2) Medium",
    __Effect: "Effekt",
    __Effects: "Effekter",
    __Focus: "Fokus",
    __Focuses: "Fokus",
    __KeyResult: "Key Result",
    __KeyResults: "Key Results",
    __KeyResultValue: "Key Result",
    __KeyResultValues: "Key Results",
    __KPI: "Key Result",
    __KPIs: "Key Results",
    __Plan: "Plan",
    __Plans: "Planer",
    __Position: "Tillståndsmål",
    __StrategicObjective: "Målsättning",
    __StrategicObjectives: "Målsättningar",
    __Target: "Målvärde",
    __Validation: "Validering",
    __Validations: "Valideringar",
    _Entity: "Ansvarig",
    _Phase: "Fas",
    AboutPage_AppBuild: "Appen skapandedatum: {{{BUILD_DATE}}}",
    AboutPage_AppDataId: "Plan Id: {{{DATA_ID}}}",
    AboutPage_AppDataVersion: "Plan Version: {{{DATA_VERSION}}}",
    AboutPage_AppVersion: "App-version: {{{VERSION}}} ({{{COMMIT}}})",
    AboutPage_Copyright: "Varning: Detta datorprogram är skyddat av upphovsrättslagar och internationella fördrag. Otillåten reproduktion eller distribution av detta program, eller någon del av det, kan resultera i stränga civilrättsliga och straffrättsliga påföljder och kommer att åtalas i största möjliga utsträckning enligt lagen.",
    AboutPage_LicenseExpires: "Giltig tom:",
    AboutPage_LicenseInfo: "Typ:",
    AboutPage_LicenseStatus: "Status:",
    AboutPage_LicenseTitle: "Licens",
    AboutPage_Trademark: "VisPlan är ett registrerat varumärke tillhörande VisPlan AB.",
    AboutPage_Website: "visplan.com",
    ActionLassoDialog_AssignedListTitle: "{{{strings.__Actions}}} tilldelade till {{{focusName}}} ({{{count}}})",
    ActionLassoDialog_ButtonAssignTitle: "Tilldela",
    ActionLassoDialog_ButtonUnassignTitle: "Frånta",
    ActionLassoDialog_CheckAllButtonLabel: "Markera alla",
    ActionLassoDialog_ColumnEndDateTitle: "Förfallodatum",
    ActionLassoDialog_ColumnNameTitle: "Namn",
    ActionLassoDialog_ColumnStartDateTitle: "Startdatum",
    ActionLassoDialog_DateRange: "{{{startDate}}} - {{{endDate}}}",
    ActionLassoDialog_FilterPlaceholderEndsAfter: "Start",
    ActionLassoDialog_FilterPlaceholderName: "{{{strings.__Action}}}",
    ActionLassoDialog_FilterPlaceholderStartsBefore: "Slut",
    ActionLassoDialog_SubTitle: "Ej tilldelade {{{strings.__Actions}}} inom tidsram för {{{strings.__Focus}}} {{{range}}}",
    ActionLassoDialog_Title: "Tilldela {{{strings.__Actions}}} till ett {{{strings.__Focus}}}",
    ActionLassoDialog_UnassignedListTitle: "Förslag på ej tilldelade {{{strings.__Actions}}} för {{{focusName}}}",
    ActionLassoDialog_UnsavedChanges: "{{{pendingChangesCount}}} väntande ändringar",
    ActionListFrame_DeleteItem: "Ta bort objekt",
    ActionListFrame_EditItem: "Ändra objekt",
    ActionListFrame_ItemCount: "Antal {{{strings.__Actions}}}: {0}",
    ActionListFrame_LinkIconTitle: "Visa i {{{strings.__Plan}}}",
    ActionListFrame_Loading: "Förbereder listvyn...",
    ActionListPercentFilter_0: "0%",
    ActionListPercentFilter_100: "75..100%",
    ActionListPercentFilter_25: "0..25%",
    ActionListPercentFilter_50: "25..50%",
    ActionListPercentFilter_75: "50..75%",
    ActionListPercentFilter_Blanks: "(Tom)",
    ActionListPercentFilter_Done: "100%",
    AddExistingActionDialog_ButtonCancel: "Avbryt",
    AddExistingActionDialog_ButtonOk: "Spara",
    AddExistingActionDialog_ButtonOkBusy: "Sparar...",
    AddExistingActionDialog_Command: "Lägg till befintlig {{{strings.__Action}}}",
    AddExistingActionDialog_SelectActionLabel: "Välj {{{strings.__Action}}}",
    AddExistingActionDialog_Title: "Lägg till befintlig {{{strings.__Action}}}",
    AppFrame_Title: "VisPlan",
    ApplicationCustomizerContext_ButtonNew: "Skapa ny VisPlan-{{{strings.__Plan}}}",
    ApplicationCustomizerContext_Text: "VisPlan har installerats men ingen {{{strings.__Plan}}} finns konfigurerad ännu för denna webbplats.",
    ArrangeComboBox_Bottom: "Justera mot nederkant",
    ArrangeComboBox_BringToFront: "Längst fram",
    ArrangeComboBox_Center: "Centrera vågrätt",
    ArrangeComboBox_GroupAlignHorizontal: "Justera vågrätt",
    ArrangeComboBox_GroupAlignVertical: "Justera lodrätt",
    ArrangeComboBox_GroupDistribute: "Fördela",
    ArrangeComboBox_GroupFrontBack: "Framåt / Bakåt",
    ArrangeComboBox_Horizontal: "Vågrätt",
    ArrangeComboBox_Left: "Vänsterjustera",
    ArrangeComboBox_Middle: "Centrera lodrätt",
    ArrangeComboBox_Right: "Högerjustera",
    ArrangeComboBox_SendToBack: "Placera längst bak",
    ArrangeComboBox_Title: "Ordna",
    ArrangeComboBox_Top: "Justera mot överkant",
    ArrangeComboBox_Vertical: "Lodrätt",
    ArrowButton_Block: "Kompakt",
    ArrowButton_BlockThin: "Kompakt tunn",
    ArrowButton_Classic: "Spjutspets",
    ArrowButton_ClassicThin: "Spjutspets tunn",
    ArrowButton_Open: "Enkel",
    ArrowButton_OpenThin: "Enkel tunn",
    ArrowButton_Title: "Pilhuvud",
    Assistant_ButtonSend: "Skicka",
    Assistant_ConsentButton: "Godkänn",
    Assistant_ConsentText: "<p>Jag förstår att genom att aktivera Microsoft Copilot kommer <strong>endast den text jag anger i dialogrutan och mina aktuella planuppgifter</strong> att skickas för beslutsfattande. VisPlan kommer inte att få tillgång till eller analysera någon annan data i min tenant.<br><strong>Dataskydd:</strong> All data som skickas till Microsoft Copilot hanteras enligt Microsofts sekretesspolicy och tjänsteavtal. <a href='https://privacy.microsoft.com' target='_blank'>Microsofts sekretesspolicy</a>.<br><strong>Opt-Out:</strong> För att inaktivera denna funktion, vänligen <a href='https://visplan.com/support' target='_blank'>kontakta oss via vårt supportformulär</a>.</p>",
    Assistant_ConsentTitle: "Samtycke för Microsoft Copilot",
    Assistant_ErrorGraphicsChanges: "Den önskade ändringen kräver ändringar i planen. Vänligen växla till grafiskt redigeringsläge först för att forstätta.",
    Assistant_PlaceholderText: "Skriv instruktion för assistenten, som 'skapa på en ny förmåga'",
    Assistant_Thinking: "Tänker...",
    AssistantCardButton_Accept: "Acceptera {{count}} ändring(ar)",
    AssistantCardButton_Reject: "Avvisa",
    AssistantCardButton_SwitchToEdit: "Byt och förök igen",
    AssistantToolbar_Caption: "VisPlan Copilot",
    AssistantToolbar_ExportLogs: "Exportera loggar",
    AssistantToolbar_Reset: "Återställ chatt",
    AssistantTooltip_AddedDP: "Tillagda  {{{strings.__Ambitions}}}",
    AssistantTooltip_AddedLOP: "Tillagda  {{{strings.__Capabilities}}}",
    AssistantTooltip_ChangedDP: "Changed  {{{strings.__Ambitions}}}",
    AssistantTooltip_ChangedLOP: "Changed {{{strings.__Capabilities}}}",
    AssistantTooltip_DeletedDP: "Deleted  {{{strings.__Ambitions}}}",
    AssistantTooltip_DeletedLOP: "Deleted {{{strings.__Capabilities}}}",
    AttachDocument_Error: "Filen kunde inte bifogas",
    AttachDocument_LinkName: "Namn (visas i menyn)",
    AttachFolder_RootName: "Webbplats",
    AuthContext_AuthError: "Behörighetsproblem",
    AuthContext_AuthErrorAction: "Uppdatera",
    AuthContext_AuthErrorExplanation: "Programmet har påträffat ett oväntat behörighetsproblem. Prova att läsa in sidan igen eller logga ut/in i Teams och gör ett nytt försök. Kontakta support om det inte fungerar.",
    AuthContext_ConsentRequired: "Medgivande krävs",
    AuthContext_ConsentRequiredAction: "Verifierar medgivande...",
    AuthContext_ConsentRequiredExplanation: "Programmet behöver ditt medgivande för att kunna ansluta till Microsoft Teams",
    AuthContext_LicenseError: "Ej giltig licens, {{{strings.__Plan}}} öppnad i visningsläge",
    AuthContext_LoadingPlan: "Läser in {{{strings.__Plan}}}...",
    AuthError_Connect: "Kan inte ansluta till webbplatsen",
    AuthError_Grant: "Om medgivandet inte trätt i kraft efter ett par försök, uppdatera sidan efter ett par minuter.",
    AuthService_ErrorGetConsent: "Medgivande kunde ej inhämtas {{{reason}}}",
    AuthService_ErrorGetTeamsToken: "Behörighetsnyckel för Teams kunde ej hittas: {{{message}}}",
    BackgroundDialog_ColorButtonText: "Bakgrundsfärg",
    BackgroundDialog_ColorButtonTitle: "Bakgrundsfärg",
    BackgroundDialog_CommandTitle: "Ställ in bakgrund",
    BackgroundDialog_Title: "Ändra bakgrund",
    BasicDialog_ActionError: "Åtgärden kunde inte utföras",
    BasicDialog_CloseMenuItem: "Stäng",
    BasicDialog_MoveMenuItem: "Flytta",
    BasicGroupName: "{{{strings.__Plan}}}",
    BinaryKeyResultValue_0: "Pågående",
    BinaryKeyResultValue_1: "Slutförd",
    BudgetProgressBar_TooltipLabel_Actual: "Utfall",
    BudgetProgressBar_TooltipLabel_Budget: "Budget",
    BudgetProgressBar_TooltipLabel_Remaining: "Kvarvarande",
    BudgetProgressBar_TooltipLabel_Spent: "Spenderat",
    BudgetProgressBar_TooltipLabel_Variance: "Avvikelse",
    Button_BrowseSite: "Bläddra bland Teams…",
    Button_Export: "Exportera",
    Button_Exporting: "Exporterar...",
    Button_LoggedIn: "Inloggad",
    Button_Login: "Logga in",
    Button_Logout: "Logga ut",
    Button_TurnOn: "Aktivera",
    ButtonApply: "Tillämpa",
    ButtonBreak: "Bryt",
    ButtonBreaking: "Bryter...",
    ButtonCancel: "Avbryt",
    ButtonClear: "Rensa",
    ButtonClose: "Stäng",
    ButtonDelete: "Ta bort",
    ButtonDeleting: "Tar bort...",
    ButtonDiscard: "Släng",
    ButtonOK: "OK",
    ButtonRefresh_Tooltip: "Läs in VisPlan-data igen",
    ButtonSave: "Spara",
    ButtonSaving: "Sparar...",
    CategoryName_Unused: "OANVÄND",
    CoachmarkButton_ContactUs: "Kontakta oss",
    CoachmarkButton_Later: "Kanske senare",
    CoachmarkButton_TryIt: "Pröva",
    CoachmarkText_Background: "Du kan använda bakgrunder",
    CoachmarkTitle_Background: "Bakgrunder",
    ColorComboBox_MoreColorsButton: "Fler färger...",
    ColorComboBox_Reset: "Ingen (återställ)",
    ColorPickerDialog_SelectColorLabel: "Välj färg",
    Command_AddAttachment: "Bifogade fil",
    Command_AssociateLOP: "{{{strings.__Capability}}}",
    Command_ChangeOrder: "Uppdaterade ordning för {{{strings.__Action}}}",
    Command_CreateItems: "Skapa {{{number}}} objekt",
    Command_DeleteAttachment: "Tog bort länk till bifogad fil",
    Command_DeleteItems: "Tog bort {{{number}}} objekt",
    Command_DeleteVisPlanItem: "Tog bort VisPlan-objekt",
    Command_DeleteVisPlanItemDescription: "Detta tar bort {{{title}}} från denna {{{strings.__Plan}}}",
    Command_DeleteVisPlanItemTitle: "Ta bort {{{kindName}}}...",
    Command_DevOpsConflictDetails: "Egenskaper för <strong>{{{title}}}</strong> i VisPlan skiljer sig från DevOps. Välj vad som ska användas (VisPlan ➔ DevOps). {{{properties}}}",
    Command_DevOpsConflictTitle: "[DEVOPS] Arbetsobjekt i DevOps skiljer sig (#{{{itemId}}}).",
    Command_DevOpsConflictUseDevOps: "DevOps",
    Command_DevOpsConflictUseVisPlan: "VisPlan",
    Command_DevOpsUpdateVisPlanItem: "[DEVOPS]: Uppdatera objekt: '{{{title}}}' från DevOps arbetsobjekt",
    Command_DevOpsUpdateWorkItem: "[DEVOPS]: Uppdatera DevOps arbetsobjekt: '{{{title}}}'",
    Command_ExportPlanError: "Kunde inte exportera {{{strings.__Plan}}}",
    Command_Failed: "Misslyckades: {{{message}}}",
    Command_Redo: "Gör om: {{{message}}}",
    Command_RefreshActionName: "Uppdatera",
    Command_RenameItem: "Ändrade namn",
    Command_SetActiveFocus: "Satte aktivt {{{strings.__Focus}}}",
    Command_SettingActionEffect: "Satte {{{strings.__Effect}}} för {{{strings.__Action}}}",
    Command_SettingActionFocus: "Tilldelade {{{strings.__Focus}}} för {{{strings.__Action}}}",
    Command_Succeeded: "Klart: {{{message}}}",
    Command_Undo: "Ångrade: {{{message}}}",
    Command_UpdateItems: "Uppdatera {{{number}}} item(s)",
    Command_UploadStyleSheetError: "Formatmallen kunde inte appliceras",
    Commands_BreakLinkMessage: "Tog bort länk för {{{title}}}",
    Commands_DeleteItemMessage: "Tog bort {{{title}}}",
    Commands_RenameItemMessage: "Bytte namn på {{{title}}} till '{{{newName}}}'",
    CommandsKeyResult_UpdateCommandMessage: "{{{strings.__KeyResult}}} ändrat",
    CommandsKeyResult_UpdateFocusListeCommandMessage: "Tidsram ändrad",
    ConfirmDeleteDialog_ButtonOkBusyLabel: "Tar bort...",
    ConfirmDeleteDialog_ButtonOkLabel: "Ta bort",
    ConfirmDeleteDialog_SubText: "Dessa objekt kommer att flyttas till papperskorgen",
    ConfirmDeleteDialog_Title: "Ta bort objekt",
    Context_Local: "Lokalt",
    Context_SharePointSite: "SharePoint-webbplats",
    CopyLinkDialog_ButtonCopy: "Kopiera",
    CopyLinkDialog_Content: "Med denna länk kan du ärva objektets progressdata (Startdatum, Förfallodatum och Procent färdigt) och koppla det till ett objekt i en annan {{{strings.__Plan}}}. Använd Ctrl+C för att kopiera länken.",
    CopyLinkDialog_ItemLink: "Objektlänk",
    CopyLinkDialog_Title: "Dela",
    CopyPlanLinkDialog_Content: "Delbar länk till objektet i Microsoft Teams.",
    CopyPlanLinkDialog_LinkLabel: "Länk",
    CustomFontDialog_EditFont: "Redigera teckensnitt",
    CustomFontDialog_EnterFontName: "Ange teckensnitt...",
    CustomFontDialog_EnterFontUrl: "Ange URL till teckensnitt...",
    CustomFontDialog_SystemFont: "Systemteckensnitt",
    CustomFontDialog_WebFont: "Webbteckensnitt",
    CustomTimeFrameDialog_EndDateLabel: "Förfallodatum",
    CustomTimeFrameDialog_StartDateLabel: "Startdatum",
    CustomTimeFrameDialog_Title: "Ange datum",
    CustomZoomDialog_FieldLabel: "Procent",
    CustomZoomDialog_Title: "Förstora till",
    Dashboard_AddNewPlanButtonLabel: "Lägg till en ny {{{strings.__Plan}}}...",
    Dashboard_MarkedAsDeleted: "Markerad som borttagen",
    Dashboard_MissingPlanDescription: "Förmodligen borttagen: {{{planId}}}",
    Dashboard_MissingPlanTitle: "Saknas",
    Dashboard_UnaccessiblePlanDescription: "Kunde inte komma åt: {{{siteUrl}}}. {{{error}}}",
    Dashboard_UnaccessiblePlanTitle: "Inte tillgänglig",
    DashboardCard_AccessedDate: "Använd {{{date}}}",
    DashboardCard_ModifiedDate: "Ändrad {{{date}}}",
    DashboardCard_OrderByModified: "Ändrad",
    DashboardCard_OrderByName: "Namn",
    DashboardCard_OrderByRecent: "Senaste",
    DashboardCardMenu_Delete: "Ta bort {{{strings.__Plan}}}",
    DashboardCardMenu_OpenPlan: "Öppna {{{strings.__Plan}}}",
    DashboardCardMenu_Pin: "Fäst",
    DashboardCardMenu_Unpin: "Ta bort",
    DashboardCommand_Connect: "Kopplar...",
    DashboardCommand_ConnectError: "Kunde inte koppla {{{strings.__Plans}}}",
    DashboardCommand_Delete: "Tar bort {{{strings.__Plan}}}...",
    DashboardCommand_DeleteError: "Kunde inte ta bort {{{strings.__Plan}}}",
    DashboardCommand_Disconnect: "Bryter koppling...",
    DashboardCommand_DisconnectError: "Kunde inte bryta koppling",
    DashboardCommand_MarkDeletedError: "Kunde inte markera kort som borttaget.",
    DashboardCommand_Pin: "Uppdaterar {{{strings.__Plan}}}...",
    DashboardCommand_PinError: "Kunde inte nåla {{{strings.__Plan}}}",
    DashboardFilter_SearchBoxPlaceholder: "Filter",
    DashboardFrame_ButtonCards: "Kort",
    DashboardFrame_ButtonTree: "Hierarki",
    DashboardPlaceholder_NoPlans: "Ingen {{{strings.__Plan}}} kunde hittas",
    DashboardPlaceholder_NoPlansButton: "Lägg till en ny {{{strings.__Plan}}}...",
    DashboardPlaceholder_NoPlansDdescription: "Skapa en ny {{{strings.__Plan}}} för att börja",
    DashboardPlanList_All: "Organisation",
    DashboardPlanList_Local: "Lokala",
    DashboardPlanList_Pinned: "Fästa",
    DashboardProgress_Discovering: "Upptäcker {{{strings.__Plans}}}...",
    DashboardProgress_Sorting: "Sorterar {{{strings.__Plans}}}…",
    DashboardService_DefaultChannel: "Webbläsare",
    DashboardService_DefaultTeam: "Lokalt",
    DashButton_11: "Punkter (1-1)",
    DashButton_12: "Punkter (1-2)",
    DashButton_14: "Punkter (1-4)",
    DashButton_22: "Streckad (2-2)",
    DashButton_42: "Streckad (4-2)",
    DashButton_44: "Streckad (4-4)",
    DashButton_Solid: "Heldragen",
    DashButton_Title: "Streck",
    DateName_Today: "Idag",
    DefaultAlignOption_Center: "Centrerad (Mitten)",
    DefaultAlignOption_Left: "Vänster (Överkant)",
    DefaultAlignOption_Right: "Höger (Underkant)",
    DefaultViewOption_FillPage: "Fyll sida",
    DefaultViewOption_FitPage: "Anpassa till sida",
    DefaultViewOption_None: "Inget",
    DeletePlanDialog_Description: "Din {{{strings.__Plan}}} kommer att raderas. Denna åtgärd kan inte ångras. Säkerställ att du har exporterat din {{{strings.__Plan}}} om du vill återställa den senare. ",
    DeletePlanDialog_Title: "Ta bort {{{strings.__Plan}}}",
    DeleteSourceLinkDialog_ButtonOk: "Ta bort länk",
    DeleteSourceLinkDialog_CheckboxDeleteChild: "Bryt länken även för underliggande objekt",
    DeleteSourceLinkDialog_Content: "Vill du bryta länken för {{{title}}} från källan?",
    DeleteSourceLinkDialog_SubTitle: "Bryt länken till källan för rapporteringen",
    DeleteSourceLinkDialog_Title: "Bryt länk",
    DeleteUrlDialog_Content: "Vill du ta bort navigeringslänken?",
    DeleteUrlDialog_ErrorMessage: "Kunde inte ta bort navigeringslänken",
    DeleteUrlDialog_Title: "Ta bort navigeringslänk",
    DevOpsData_DevOpsTaskLinkName: "Länka till arbetsobjekt '{{{taskName}}}' i Microsoft DevOps '{{{projectName}}}'",
    DevOpsLinkEditor_ExistingItems: "Befintliga objekt",
    DevOpsLinkEditor_ItemTypeLabel: "Arbetsobjektstyp",
    DevOpsLinkEditor_NoResultsFound: "Inga träffar",
    DevOpsLinkEditor_PickerLabel: "Sök efter DevOps-arbetsobjekt (eller ange arbetsobjekts-ID här)",
    DevOpsLinkEditor_PickerPlaceholder: "Ange arbetsobjekt-ID eller sök efter arbetsobjekt",
    DevOpsLinkEditor_ProjectLabel: "Projekt",
    DevOpsNotification_AuthorizationRequired: "[Microsoft DevOps] Auktorisering krävs",
    DevOpsNotification_AuthorizationRequiredError: "Användaren eller en administratör måste ge sitt medgivande för att ge åtkomst till Microsoft DevOps",
    DevOpsNotification_BreakDevOpsLink: "Bryt länk till Azure DevOps",
    DevOpsNotification_BreakDevOpsLinkDescription: "Detta tar bort länken {{{title}}} till Microsoft Planner.",
    DevOpsNotification_BreakLinkTitle: "Bryt länk...",
    DevOpsNotification_ButtonAuthorize: "Auktoriserar...",
    DevOpsNotification_ConsentGrant: "[Microsoft DevOps] Bevilja medgivande",
    DevOpsNotification_ConsentRequiredDescription: "Medgivande krävs {{{error}}}",
    DevOpsWizard_ConfigPageSubTitle: "Konfigurera Microsoft DevOps Integration",
    DevOpsWizard_Error: "Kan inte ansluta till Microsoft DevOps",
    DevOpsWizard_ProgressPageSubTitle: "Spara konfiguration",
    DevOpsWizard_StartPage_EnableBoxTitle: "Aktivera Microsoft DevOps-integration",
    DevOpsWizard_StartPage_UrlPlaceholder: "Ange webbadress till Microsoft DevOps",
    DevOpsWizard_StartPageSubTitle: "Aktivera Microsoft DevOps-integration",
    DevOpsWizard_Title: "Microsoft DevOps-integration",
    DevOpsWizard_WorkItemTypesLabel: "Välj vilka arbetsobjekt att ta med",
    DiagramProgressTooltip: "Progress: {{{percentComplete}}}",
    DialogDiscard_Message: "Du har {{{pendingChangesCount}}} väntande ändringar. Om du avbryter nu kommer ändringarna inte att verkställas. Spara eller släng dessa ändringar.",
    DialogDiscard_Title: "Osparade ändringar",
    DialogLabel_ParentKeyResult: "Överordnat {{{strings.__KeyResult}}}",
    Drawing_KeyResultTooltip: "{{{krName}}}",
    DuplicateSourceLink_CommandDetails: "Dubblettlänk funnen på <strong>{{{item}}}</strong> och <strong>{{{duplicate}}}</strong>: {{{link}}}",
    DuplicateSourceLink_CommandMessage: "Dubblettlänk är funnen på objektet <strong>{{{duplicate}}}</strong>.",
    DuplicateSourceLink_CommandTitle: "Bryt länk",
    DuplicateSourceLink_DialogContent: "Ta bort länken från {{{title}}}.",
    DuplicateSourceLink_DialogTitle: "Bryt dubblettlänk",
    EditActionDialog_AssigneeLabel: "Tilldelad",
    EditActionDialog_CapabilityLabel: "{{{strings.__Capability}}}",
    EditActionDialog_EndDateErrorMessage: "Ska vara efter Startdatum",
    EditActionDialog_EndDateLabel: "Förfallodatum",
    EditActionDialog_EndDateRequiredErrorMessage: "Måste ha ett Förfallodatum när du angett ett Startdatum",
    EditActionDialog_FocusLabel: "{{{strings.__Focus}}}",
    EditActionDialog_KpiLabel: "{{{strings.__KPI}}}",
    EditActionDialog_LinkedItemLabel: "Progressdata ärvs från det länkade objektet",
    EditActionDialog_LinkedItemLabelLink: "Öppna",
    EditactionDialog_PercentCompleteErrorMessage: "Procent färdigt måste vara mellan 0% och 100%",
    EditActionDialog_PercentCompleteLabel: "Procent färdigt",
    EditActionDialog_StartDateErrorMessage: "Ska vara före Förfallodatum",
    EditActionDialog_StartDateLabel: "Startdatum",
    EditActionDialog_StartDateRequiredErrorMessage: "Måste ha ett Startdatum när du angett ett Förfallodatum",
    EditActionDialog_UseFocusDatesLabel: "Använd datum från {{{strings.__Focus}}}",
    EditAssocDialog_ActionSelectorLabel: "{{{strings.__Action}}}",
    EditAssocDialog_ConfidenceHigh: "Hög",
    EditAssocDialog_ConfidenceLow: "Låg",
    EditAssocDialog_ConfidenceMedium: "Medium",
    EditAssocDialog_ConfidenceSelectorLabel: "Påverkan",
    EditAssocDialog_KeyResultSelectorLabel: "{{{strings.__KeyResult}}}",
    EditAssocDialog_KpiSelectorLabel: "{{{strings.__KPI}}}",
    EditAssocDialog_OptionSoUnrelated: "{{{soName}}} (Ej Validerad)",
    EditAssocDialog_OptionSoValidated: "{{{soName}}} (Validerad)",
    EditAssocDialog_ShowOnDiagram_Label: "Visa i {{{strings.__Plan}}} ({{{strings..__Ambition}}})",
    EditAssocDialog_StrategicObjectiveSelectorLabel: "{{{strings.__StrategicObjectives}}}",
    EditAssocDialog_WhyLabel: "Motivering",
    EditCustomStringsDialog_Title: "Redigera kundanpassade begrepp",
    EditFocusDialog_EndDateLabel: "Förfallodatum",
    EditFocusDialog_LinkRelatedActionsButtonLabel: "Länka relaterade {{{strings.__Action}}} ({{{confirmed}}}/{{{candidate}}})",
    EditFocusDialog_NameLabel: "Namn",
    EditFocusDialog_StartDateLabel: "Startdatum",
    EditFocusList_ButtonAdd: "Lägg till...",
    EditFocusList_ButtonDelete: "Ta bort",
    EditFocusList_ButtonEdit: "Ändra...",
    EditFocusList_ColumnEndDate: "Förfallodatum",
    EditFocusList_ColumnName: "Namn",
    EditFocusList_ColumnStartDate: "Startdatum",
    EditKeyResultBlock_UseExcelLabel: "Använd Excel:",
    EditKeyResultBlock_UseExcelNo: "Nej",
    EditKeyResultBlock_UseExcelYes: "Ja",
    EditKeyResultDialog_DecimalPoints_Label: "Decimaler",
    EditKeyResultDialog_Inverse_Label: "Omvänd indikator",
    EditKeyResultDialog_KeyResultTypeBinary: "Binär (Pågående / Slutförd)",
    EditKeyResultDialog_KeyResultTypeBudget: "Budget (Spenderat / Budget)",
    EditKeyResultDialog_KeyResultTypeLabel: "Typ",
    EditKeyResultDialog_KeyResultTypeNumeric: "Numerisk (Utfall / Målvärde)",
    EditKeyResultDialog_ParentKeyResultLabel: "Överordnat {{{strings.__KeyResult}}}",
    EditKeyResultDialog_ShowOnDiagram_Label: "Visa i {{{strings.__Plan}}} på {{{strings.__Position}}}",
    EditKeyResultDialog_Units_Label: "Måttenhet",
    EditKeyResultList_ButtonAddActual: "Lägg till {{{strings.__Actual}}}...",
    EditKeyResultList_ButtonAddTarget: "Lägg till {{{strings.__Target}}}...",
    EditKeyResultList_ButtonDelete: "Ta bort",
    EditKeyResultList_ButtonEdit: "Ändra...",
    EditKeyResultList_ColumnBinaryValue: "Slutförd",
    EditKeyResultList_ColumnComment: "Kommentar",
    EditKeyResultList_ColumnDate: "Datum",
    EditKeyResultList_ColumnValue: "Värde ({{{units}}})",
    EditKeyResultList_ItemDate: "{{{date}}} ({{{namedDate}}})",
    EditKeyResultListChart_LabelActual: "{{{strings.__Actual}}}",
    EditKeyResultListChart_LabelTarget: "{{{strings.__Target}}}",
    EditKeyResultValueDialog_CommentLabel: "Kommentar",
    EditKeyResultValueDialog_DateLabel: "Datum",
    EditKeyResultValueDialog_DuplicateDateWarning: "Det finns redan ett värde för datumet {{{date}}}",
    EditKeyResultValueDialog_DuplicateDateWarningDetails: "{{{value}}} {{{units}}}",
    EditKeyResultValueDialog_NameLabel: "Namngivet datum",
    EditKeyResultValueDialog_ValueLabel: "Värde",
    EditKeyResultValuesDialog_ConfigureButtonLabel: "Konfigurera...",
    EditKeyResultValuesDialog_SubTitle: "{{{description}}} ({{{parentTitle}}})",
    EditKeyResultValuesDialog_Title: "{{{itemName}}}",
    EditMsDevOpsActionDialog_Title: "Lägg till {{{strings.__Action}}} från Microsoft DevOps",
    EditMsProjectActionDialog_Title: "Lägg till {{{strings.__Action}}} från Microsoft Project",
    EditMultipleActionsDialog_Details: "Uppdatera flera Objekt ({{count}})",
    EditMultipleActionsDialog_Title: "Uppdatera objekt",
    EditPlanPropertyDialog_ButtonOkBusyLabel: "Sparar...",
    EditPlanPropertyDialog_ButtonOkLabel: "Spara",
    EditPlanPropertyDialog_Command: "Uppdatera {{{strings.__Plan}}}-egenskap för {{{property}}}",
    EditSourceLink_DevOpsTab: "Microsoft DevOps",
    EditSourceLink_ProjectTab: "Microsoft Project Online",
    EditSourceLink_UrlTab: "URL",
    EditSourceLink_VisplanTab: "VisPlan",
    EditSourceLinkDialog_ButtonOk: "Länka",
    EditSourceLinkDialog_ButtonOkBusy: "Länkar...",
    EditSourceLinkDialog_Command: "Uppdatera länk till källa {{{itemName}}}",
    EditSourceLinkDialog_FieldLabel: "Länk till källa",
    EditSourceLinkDialog_Reading: "Hämtar information från den länkade källan...",
    EditSourceLinkDialog_Text: "Lägg till en webbadress till ett annat VisPlan-objekt kopierat från 'Hämta länk'-kommandot för att ärva dess progressdata. (Startdatum, Förfallodatum och Procent färdigt)",
    EditSourceLinkDialog_TitleEdit: "Länka progressdata",
    EditSourceLinkDialog_TItleView: "Visa progressdata",
    EditSoureLinkDialog_ErrorReadProgress: "Kunde inte läsa den länkade progressen för objektet",
    EditUrlDialog_DescriptionLabel: "Beskrivning",
    EditUrlDialog_ErrorMessage: "Kunde inte spara navigeringslänk",
    EditUrlDialog_LinkLabel: "Länk",
    EditUrlDialog_Title: "Ändra navigeringslänk",
    EndDate: "Förfallodatum:",
    EnterKey_DialogTitle: "Ange licensnyckel",
    EnterKey_InvalidKey: "Licensnyckeln stämmer inte. Kontrollera att du angett rätt licensnyckel.",
    EnterKey_KeyLabel: "Licensnyckeln",
    EnterKey_Validating: "Verifierar...",
    Error_InvalidUserName: "Ogiltig användare",
    Error_ResolveUsers_PlannerTask: "Planner-uppgift",
    Error_ResolveUsers_Title: "Kunde inte hitta tilldelad person {{{key}}} för {{{itemTitle}}}",
    Error_SpCreateItems: "skapa objekt {{{itemTitle}}}",
    Error_SpDeleteItems: "radera objekt {{{itemTitle}}}",
    Error_SpUpdateItems: "uppdatera objekt {{{itemTitle}}}",
    ErrorMessage_EnsurePercentComplete: "Fel! Kunde inte uppdatera Procent färdigt för {{{strings.__Plan}}}",
    ErrorMessage_NoDynamicConsentSPFx: "SPFx versionen av programmet stödjer inte dynamiskt medgivande. Kontakta din administratör för att godkänna programmet för din organisation.",
    ErrorMessage_PluralMissing: "Pluralform saknas",
    ErrorMessage_SharepointUnableToResolveUsers: "Kunde inte hitta användare: {{{userNames}}}",
    ErrorMessage_SingularMissing: "Singularform saknas",
    ErrorMessage_UnableToLoadPlan: "{{{strings.__Plan}}} {{{planId}}} kunde inte läsas in. {{{error}}}",
    ErrorMessage_UnableToReadPlanFromFile: "{{{strings.__Plan}}} kunde inte läsas in från fil {{{url}}}. {{{error}}}",
    ErrorMessage_UpdatingLinkedProgress: "Fel vid inläsning av länkade VisPlan objekt från anslutna {{{strings.__Plans}}}. {{{strings.__Plan}}} visas med tidigare sparad data.",
    ExcelBlockLabel_ButtonCreate: "Skapa",
    ExcelBlockLabel_ButtonRefresh: "Uppdatera",
    ExcelBlockLabel_CommentColumn: "Kolumn för Kommentarer",
    ExcelBlockLabel_DateColumn: "Datum-kolumn",
    ExcelBlockLabel_Table: "Tabell",
    ExcelBlockLabel_ValueColumn: "Värde-kolumn",
    ExcelBlockLabel_Workbook: "Arbetsbok",
    ExcelBlockStatus_CheckingFileStatus: "Kontrollerar filens status…",
    ExcelBlockStatus_NoWorkbook: "Ej ansluten till Excel-arbetsbok",
    ExcelBlockStatus_OpenExcelWorkbook: "Öppna Excel-arbetsbok",
    ExcelBlockStatus_Processing: "Arbetar...",
    ExcelConsent_Button: "Auktorisera",
    ExcelConsent_Details: "För att använda Excelfiler måste du tillåta applikationen att komma åt dina Excelfiler. Klicka på knappen nedan för att bevilja åtkomst",
    ExcelConsent_Failed: "Kan inte erhålla samtycke för Excel",
    ExcelConsent_Message: "Samtycke krävs för Excel",
    ExcelConsent_Succeeded: "Samtycke beviljat",
    ExcelError_CannotGetDefaultOptions: "Kan inte ge standardalternativen för Excel",
    ExcelError_CreateFile: "Det gick inte att skapa filen {{{fileName}}} i mappen {{{folderPath}}}. {{{message}}}",
    ExcelError_CreateFolder: "Det gick inte att skapa mappen {{{folderPath}}}. {{{message}}}",
    ExcelError_CreateSession: "Det gick inte att skapa Excel-sessionen. {{{message}}}",
    ExcelError_CreateTable: "Det gick inte att skapa Excel-tabellen {{{excelTable}}} för {{{krTitle}}}. {{{message}}}",
    ExcelError_CreateWorksheet: "Det gick inte att skapa Excel-kalkylblad {{{excelTable}}} för {{{krTitle}}}. {{{message}}}",
    ExcelError_OpenWorkbook: "Det gick inte att öppna Excel-arbetsboken {{{excelWorkbook}}} för {{{krTitle}}}. {{{message}}}",
    ExcelError_ReadData: "Det gick inte att hämta data från Excel-tabellen {{{excelTable}}} för {{{krTitle}}}. {{{message}}}",
    ExcelError_RefreshSession: "Det gick inte att uppdatera Excel-sessionen. {{{message}}}",
    ExcelExportDialog_ExportError: "Kunde inte exportera listan till Excel",
    ExcelExportDialog_Title: "Exportera till Excel",
    ExcelService_CreateError: "Kunde inte skapa Excel-arbetsbok",
    ExcelService_RefreshError: "Kunde inte hämta data från Excel",
    ExportImage_IncludeIndicators: "Inkludera indikatorer",
    ExportLinkPage_Text: "{{{strings.__Plan}}} har exporterats. Klicka på länken nedan för att ladda ner en kopia lokalt.",
    ExportPlan_UnableToCreateTheImage: "Bilden kunde inte skapas",
    ExportPlan_UnableToUploadTheImage: "Bilden kunde inte laddas upp",
    ExportPlanDialog_Title: "Exportera {{{strings.__Plan}}}",
    ExportPlanFileNamePage_FileNameLabel: "Filnamn",
    ExportPlanFileNamePage_Title: "Ange ett namn för filen och välj en plats där exporten av {{{strings.__Plan}}} kan skapas",
    ExportPlanFilterPage_Default: "(förvalt)",
    ExportPlanFilterPage_FilterItems: "Filtrera objekt",
    ExportPlanFilterPage_IncludeAllItems: "Inkludera alla objekt",
    ExportPlanFilterPage_RemoveAssignees: "Ta bort tilldelade",
    ExportPlanFilterPage_RemoveExistingLinks: "Ta bort befintliga länkas för progress",
    ExportPlanJsonDialog_UploadError: "Filen kunde inte laddas upp",
    ExportPlanSvgDialog_Title: "Exportera {{{strings.__Plan}}} i SVG-format",
    FillColorComboBox_Title: "Fyllnadsstil",
    FontColorComboBox_TItle: "Textstil",
    FontComboBox_BuiltIn: "Inbyggda teckensnitt",
    FontComboBox_Custom: "Anpassade teckensnitt",
    FontComboBox_CustomOther: "Anpassa...",
    FormatTitle_Assoc: "{{{from}}} ➔ {{{to}}}",
    FormatTitle_Default: "{{{kindName}}} '{{{itemName}}}'",
    FormatTitle_KRV: "{{{kindName}}} ({{{valueKindName}}}) {{{valueDate}}} ({{{value}}})",
    Gantt_NoAssignee: "(Ej tilldelad)",
    Gantt_NoFocus: "(Inget {{{strings.__Focus}}})",
    GanttComponent_EditSelected: "Ändra valda...",
    GanttComponent_Export: "Export",
    GanttComponent_Today: "Idag",
    GanttExport_DialogTitle: "Exportera Gantt till PDF",
    GanttExport_ErrorMessage: "Kunde inte exportera PDF-filen",
    GanttExportDateRange_All: "Alla",
    GanttExportDateRange_Custom: "Anpassad",
    GanttExportDateRange_Visible: "Synliga",
    GanttExportLabel_DateRange: "Datumintervall",
    GanttExportLabel_EndDate: "Förfallodatum",
    GanttExportLabel_Format: "Sidstorlek",
    GanttExportLabel_Landscape: "Liggande",
    GanttExportLabel_Mode: "Export-läge",
    GanttExportLabel_StartDate: "Startdatum",
    GanttExportMode_All: "Alla",
    GanttExportMode_Chart: "Diagram",
    GanttExportMode_TreeList: "Träd-vy",
    GanttFrame_GroupBy: "Gruppera efter {{{title}}}",
    GanttFrame_GroupByAssignee: "Gruppera efter Tilldelad",
    GanttFrame_Loading: "Förbereder Gantt-vy…",
    GanttFrame_TaskUpdated: "Uppdatera {{count}} objekt från Gantt",
    GanttName: "Namn",
    GanttWarning_ProgressChangeIgnored: "Progress-ändringar ignorerades",
    GanttWarning_ProgressChangeIgnoredDetails: "{{{title}}} är länkad till en annan källa för progressen. Ändringar i Gantt-vyn kommer därför att ignoreras.",
    GettingStarted_ShowOnStartup: "Visa inte igen",
    GettingStartedDialog_Title: "Kom igång",
    GraphNotification_AuthorizationRequired: "[Graph] Väntar på medgivande",
    GraphNotification_AuthorizationRequiredError: "Medgivande från användare eller administratör krävs för att visa bilder från användarprofiler. Kontakta din Microsoft 365 administratör om du inte får godkänna denna förfrågan.",
    GraphNotification_AuthorizationRequiredErrorDescription: "Medgivande krävs",
    GraphNotification_AuthorizeButton: "Verifiera",
    GraphNotification_ConsentGrant: "[Graph] Bevilja medgivande",
    HistoryBlock_AuditLogDialogTitle: "Versionshistorik",
    HistoryBlock_DialogTitle: "Välj datum",
    HistoryBlock_ErrorReadingHistory: "Kunde inte hämta versionshistorik",
    HistoryBlock_ItemCreatedBy: "skapat av",
    HistoryBlock_ItemUpdatedBy: "ändrat av",
    HistoryBlock_ReadingHistory: "Läser versionshistorik",
    HistoryBlock_Title: "Versionshistorik: {{{date}}}",
    ImageDialog_ChangeButton: "Ändra...",
    ImageDialog_EditImage: "Ändra bild",
    ImageDialog_FixedAspectRatio: "Låst bredd-höjd-förhållande",
    ImageDialog_ImagePlacement: "Bildplacering",
    ImageDialog_ResetButton: "Återställ",
    ImageDialog_ResizeFill: "Fyll området (låst höjd och breddförhållande)",
    ImageDialog_ResizeFit: "Anpassa (låst höjd och breddförhållande)",
    ImageDialog_ResizeNone: "Sträck över",
    ImageOpacitySlider_Label: "Transparens",
    InfoBar_SelectItemButton: "Markera objekt",
    InvalidLicenseStatus_Missing: "hittades inte",
    InvalidLicenseStatus_PlanMismatch: "{{{strings.__Plan}}} stämmer inte",
    InvalidLicenseStatus_SiteMismatch: "webbplatsen stämmer inte",
    ItemEditDialog_ButtonOkCreate: "Skapa",
    ItemEditDialog_ButtonOkCreateBusy: "Skapar...",
    ItemEditDialog_ButtonOkUpdate: "Uppdatera",
    ItemEditDialog_ButtonOkUpdateBusy: "Uppdaterar...",
    ItemEditDialog_CommandCreate: "Skapa {{{itemName}}}",
    ItemEditDialog_CommandUpdate: "Uppdatera {{{itemName}}}",
    ItemEditDialog_TitleCreate: "Skapa {{{vistoKindName}}}",
    ItemEditDialog_TitleUpdate: "Uppdatera {{{vistoKindName}}}",
    ItemEditDialog_TitleView: "Visa {{{vistoKindName}}}",
    ItemPicker_ExistingItems: "Befintligt objekt",
    ItemPicker_Loading: "Laddar...",
    ItemPicker_NoResultsFound: "Inga resultat",
    ItemPicker_SearchForItems: "Sök efter objekt...",
    KeyResultType_Binary: "KeyResultType_Binary",
    KeyResultType_Budget: "KeyResultType_Budget",
    KeyResultType_Numeric: "KeyResultType_Numeric",
    LabeChangedCommand_RenamePlan: "Byt namn på {{{strings.__Plan}}}",
    LabeChangedCommand_RenamePosition: "Ändra {{{strings.__Position}}}",
    LanguageDropdown_English: "Engelska",
    LanguageDropdown_Label: "Språk",
    LanguageDropdown_Swedish: "Svenska",
    LegendLabel_Focus: "{{{strings.__Focus}}}",
    LegendLabel_Today: "Idag",
    License_PlanContact: "<a href='https://visplan.com/book-a-demo' target='_blank'/>Boka ett möte</a>för att se VisPlans fulla potential.",
    License_PlanExpires: "{{{strings.__Plan}}} upphör att gälla {{{date}}}",
    LicenseEnter_Button: "Ange nyckel",
    LicenseExpiration_Label: "Upphör att gälla",
    LicenseRequest_Button: "Nyckelförfrågan...",
    LicenseStatus_Invalid: "Ogiltig",
    LicenseStatus_Label: "Licensstatus",
    LicenseStatus_Valid: "Giltig",
    LinkIconTitle_DevOps: "DevOps-länk",
    LinkIconTitle_Generic: "Länk",
    LinkIconTitle_Planner: "Planner-länk",
    LinkIconTitle_Project: "Project-länk",
    LinkIconTitle_VisPlan: "VisPlan-länk",
    LinkName_VisPlan: "[VisPlan]: Länka till VisPlan {{{linkKind}}} '{{{spItemTitle}}}'",
    LoadingIndicator_Authenticating: "Verifierar...",
    LoadingIndicator_Connecting: "Ansluter...",
    LocalStorageService_CreatePlanLocalStorage: "Skapa VisPlan i webbläsarens lokala lagring",
    MainFrame_ErrorLoadingItems: "Fel vid inläsning av {{{strings.__Plan}}}-objekt",
    MainFrame_ErrorMissingPlanDescription: "{{{strings.__Plan}}} kunde inte hittas i SharePoint webbplatsen (Kan den ha raderats?)",
    MainFrame_ErrorMissingPlanTitle: "{{{strings.__Plan}}} kan inte hittas",
    MainFrame_ErrorUpdatingProgress: "Fel vid uppdatering av länkade data.",
    MainFrame_ErrorValidatingPlan: "{{{strings.__Validation}}} misslyckades",
    MainFrame_LicenseValid: "Giltig",
    MainFrame_Menu_DevOps: "Microsoft DevOps",
    MainFrame_Menu_DevOps_Open: "Öppna",
    MainFrame_Menu_DevOps_Wizard: "Kör Konfigureringsguiden...",
    MainFrame_Menu_ExportPlanData: "Exportera data från {{{strings.__Plan}}}",
    MainFrame_Menu_ExportPlanPNG: "Exportera {{{strings.__Plan}}} som en PNG-bild",
    MainFrame_Menu_ExportPlanSVG: "Exportera {{{strings.__Plan}}} som en SVG-bild",
    MainFrame_Menu_ExportTemplate: "Exportera mall...",
    MainFrame_Menu_Help: "Hjälp...",
    MainFrame_Menu_License: "Licens...",
    MainFrame_Menu_Planner: "Microsoft Planner",
    MainFrame_Menu_Planner_Open: "Öppna",
    MainFrame_Menu_Planner_Wizard: "Kör konfigureringsguiden...",
    MainFrame_Menu_PlanViewSettings: "{{{strings.__Plan}}}-inställningar...",
    MainFrame_Menu_Print: "Skriv ut...",
    MainFrame_Menu_Project: "Microsoft Project",
    MainFrame_Menu_Project_Open: "Öppna",
    MainFrame_Menu_Project_Wizard: "Kör Konfigureringsguiden...",
    MainFrame_Menu_Support: "Support...",
    MainFrame_UnableToSwitchEdit: "Kunde inte öppna redigeringsläge, vänligen försök igen.",
    MainTab_Gantt: "Gantt",
    MainTab_List: "Lista",
    MainTab_LoadingPlanData: "Laddar {{{strings.__Plan}}}-data...",
    MainTab_Matrix: "Matris",
    MainTab_OKR: "OKR",
    MainTab_Plan: "{{{strings.__Plan}}}",
    MainTab_UpdatingProgress: "Uppdaterar länkad progress",
    Matrix_PlanSelectDialog_Button: "{{{strings.__Plan}}}-inställningar",
    Matrix_PlanSelectDialog_SubTitle: "Välj {{{strings.__Plan}}} att visa i denna Matris",
    Matrix_PlanSelectDialog_Title: "Välj {{{strings.__Plan}}}",
    MatrixChartDialog_KpiActuals: "{{{type}}} '{{{title}}}' utfall",
    MatrixChartDialog_KpiTargets: "{{{type}}} '{{{title}}}' målvärden",
    MatrixChartDialog_KrActuals: "{{{type}}} '{{{title}}}' utfall",
    MatrixChartDialog_KrTargets: "{{{type}}} '{{{title}}}' målvärden",
    MatrixChartDialog_Title: "{{{strings.__KeyResult}}} jämfört {{{strings.__KPI}}}",
    MatrixColumnAction: "{{{strings.__Action}}}",
    MatrixColumnConfidence: "Påverkan",
    MatrixColumnDP: "{{{strings.__Ambition}}}",
    MatrixColumnEffect: "{{{strings.__Effect}}}",
    MatrixColumnFocus: "{{{strings.__Focus}}}",
    MatrixColumnKPI: "2a {{{strings.__KPI}}}",
    MatrixColumnKR: "1a {{{strings.__KeyResult}}}",
    MatrixColumnLOP: "{{{strings.__Capability}}}",
    MatrixColumnPercentComplete: "Procent färdigt",
    MatrixColumnSO: "{{{strings.__StrategicObjective}}}",
    MatrixColumnWhy: "Motivering",
    MatrixFrame_FilterByFocus: "Filtrera på {{{strings.__Focus}}}",
    MatrixFrame_NoDataDescription: "Skapa minst en {{{strings.__Validation}}} mellan {{{strings.__Action}}} och {{{strings.__StrategicObjective}}} för att börja använda matrisen.",
    MatrixFrame_NoDataTitle: "Det finns ingen data att visa i matrisen just nu.",
    MatrixFrame_ShowAllToggle: "Visa alla",
    MatrixFrame_ShowCompleted: "Slutförda",
    MatrixFrame_ShowValidatedOnly: "Endast validerade",
    MatrixFrame_SomeActionsNotShow: "En eller flera {{{strings.__Actions}}} visas inte. Validera {{{strings.__Actions}}} mot {{{strings.__StrategicObjectives}}} för att de skall visas i denna vy.",
    MatrixFrame_SomeDpsNotShow: "En eller flera {{{strings.__Ambitions}}} visas inte. Valideras dessa {{{strings.__Ambitions}}} mot ett {{{strings.__StrategicObjective}}} för att de ska visas i denna vy.",
    MatrixFrame_SomeObjectivesNotShow: "Ett eller flera {{{strings.__StrategicObjectives}}} visas inte. Validera {{{strings.__Actions}}} mot {{{strings.__StrategicObjectives}}} för att de skall visas i denna vy.",
    MatrixFrame_TabActionsKeyResults: "{{{strings.__Actions}}} ➔ {{{strings.__KeyResults}}}",
    MatrixFrame_TabActionsKeyResultsKPI: "{{{strings.__KPI}}} ➔ {{{strings.__KeyResults}}}",
    MatrixFrame_TabActionsObjectives: "{{{strings.__Actions}}} ➔ {{{strings.__StrategicObjectives}}}",
    MatrixFrame_TabAmbitionsObjectives: "{{{strings.__Ambitions}}} ➔ {{{strings.__StrategicObjectives}}}",
    MatrixFrame_TabFocusResults: "{{{strings.__Focuses}}} ➔ {{{strings.__KeyResults}}}",
    MatrixFrame_TooltipFilterByFocus: "Visa {{{strings.__Actions}}} från valt {{{strings.__Focus}}}",
    MatrixFrame_TooltipShowAllToggle: "Visa slutförda och validerade {{{strings.__Actions}}}",
    MatrixFrame_TooltipShowCompleted: "Visar även slutförda {{{strings.__Actions}}}",
    MatrixFrame_TooltipShowValidatedOnly: "Visar endast validerade {{{strings.__Actions}}}",
    MatrixLegend_Title: "Påverkan av {{{strings.__Action}}}",
    MatrixTooltip_AddValidation: "Skapa ny {{{strings.__Validation}}}",
    MatrixTooltip_Collapse: "Klicka för att dölja",
    MatrixTooltip_Expand: "Klicka för att utöka",
    Menu_AttachDocument: "Bifoga fil",
    Menu_AttachDownload: "Ladda ner",
    Menu_AttachOpen: "Öppna",
    Menu_AttachUnlink: "Ta bort bifogad fil",
    Menu_ProgressLink: "Länkning av Progress",
    MenuFrame_Menu_GetTeamsLink: "Dela...",
    MenuItem_AddDevOpsAction: "Lägg till Microsoft DevOps {{{strings.__Action}}}...",
    MenuItem_AddExistingAction: "Lägg till befintlig {{{strings.__Action}}}...",
    MenuItem_AddProjectAction: "Lägg till Microsoft Project {{{strings.__Action}}}...",
    MenuItem_AssignActions: "Tilldela {{{strings.__Actions}}}..",
    MenuItem_AssignEffect: "Tilldela {{{strings.__Effect}}} för {{{strings.__Action}}}",
    MenuItem_AssignFocus: "Tilldela {{{strings.__Focus}}}",
    MenuItem_BreakProgressLink: "Ta bort Progress-länk...",
    MenuItem_ClearNotifications: "Rensa",
    MenuItem_Collapse: "Dölj",
    MenuItem_CopyLink: "Hämta länk för detta objekt...",
    MenuItem_Delete: "Ta bort",
    MenuItem_DeleteAction: "Ta bort {{{strings.__Action}}}",
    MenuItem_DeleteAssoc: "Ta bort {{{strings.__Validation}}}",
    MenuItem_DeleteEffect: "Ta bort Effekt...",
    MenuItem_DeleteFocus: "Ta bort {{{strings.__Focus}}}",
    MenuItem_DeleteKPI: "Ta bort {{{strings.__KPI}}}...",
    MenuItem_DeleteKR: "Ta bort {{{strings.__KeyResult}}}...",
    MenuItem_DeleteNavigationLink: "Ta bort navigeringslänk...",
    MenuItem_DeleteSo: "Ta bort {{{strings.__StrategicObjective}}}",
    MenuItem_EditAction: "Ändra {{{strings.__Action}}}...",
    MenuItem_EditAll: "Ändra egenskaper...",
    MenuItem_EditDp: "Ändra {{{strings.__Ambition}}}...",
    MenuItem_EditEffect: "Ändra Effekt...",
    MenuItem_EditFocus: "Ändra {{{strings.__Focus}}}...",
    MenuItem_EditImage: "Ändra bild",
    MenuItem_EditKPIValue: "Ändra {{{strings.__KPI}}}...",
    MenuItem_EditKRValue: "Ändra {{{strings.__KeyResult}}}...",
    MenuItem_EditNavigationLink: "Ändra navigeringslänk...",
    MenuItem_EditProgressLinkMenuItem: "Ändra Progress-länk...",
    MenuItem_EditSo: "Ändra {{{strings.__StrategicObjective}}}",
    MenuItem_EditValidation: "Ändra {{{strings.__Validation}}}...",
    MenuItem_Expand: "Utöka",
    MenuItem_getMoveActionMenuItem: "Flytta {{{strings.__Action}}} till annan {{{strings.__Ambition}}}",
    MenuItem_getMoveKeyResultMenuItem: "Flytta {{{strings.__KPI}}} till en annan {{{strings.__Ambition}}}",
    MenuItem_HideAction: "Dölj {{{strings.__Action}}}",
    MenuItem_LinkToProgressData: "Länka till progressdata...",
    MenuItem_NavigationLink: "Navigeringslänk",
    MenuItem_NewAction: "Ny {{{strings.__Action}}}...",
    MenuItem_NewAssoc: "Ny {{{strings.__Validation}}}...",
    MenuItem_NewEffect: "Ny  {{{strings.__Effect}}}…",
    MenuItem_NewFocus: "Nytt {{{strings.__Focus}}}",
    MenuItem_NewKPI: "Ny {{{strings.__KPI}}}...",
    MenuItem_NewKR: "Nytt {{{strings.__KeyResult}}}...",
    MenuItem_NewSo: "Ny {{{strings.__StrategicObjective}}}",
    MenuItem_OpenProgressLinkMenuItem: "Öppna länkat objekt",
    MenuItem_SetActiveFocus: "Ange som aktivt {{{strings.__Focus}}}",
    MenuItem_ShowAdvisory: "Handledning",
    MenuItem_ShowChart: "Visa diagram…",
    MenuItem_ShowHistoryDialog: "Historik",
    MenuItem_Top_Show: "Visa",
    MenuItem_UnassignFocusKPI: "Frånta {{{strings.__KPI}}}",
    MenuItem_ViewAction: "Visa {{{strings.__Action}}}...",
    MenuItem_ViewAll: "Visa alla egenskaper...",
    MenuItem_ViewDp: "Visa {{{strings.__Ambition}}}...",
    MenuItem_ViewEffect: "Visa {{{strings.__Effect}}}...",
    MenuItem_ViewFocus: "Visa {{{strings.__Focus}}}...",
    MenuItem_ViewKPIValue: "Visa {{{strings.__KPI}}}...",
    MenuItem_ViewKRValue: "Visa {{{strings.__KeyResult}}}...",
    MenuItem_ViewSo: "Visa {{{strings.__StrategicObjective}}}...",
    MenuItem_ViewValidation: "Visa {{{strings.__Validation}}}...",
    MenuItems_ShowAssessments: "Bedömning",
    MenuItems_ShowAssignees: "Tilldelade",
    MenuItems_ShowEffects: "{{{strings.__Effects}}}",
    MenuItems_ShowHistory: "Versionshistorik",
    MenuItems_ShowIndicators: "OKR-indikatorer",
    MenuItems_ShowProgress: "Progress",
    MenuItems_ShowValidations: "{{{strings.__Validations}}}",
    MenuName_CustomTimeFrame: "Anpassad tidsram...",
    MenuName_DefineTimeFrames: "Skapa tidsram...",
    Message_FeatureDisabled: "Funktionen {{feature}} ingår inte i den nuvarande prenumerationen",
    Message_LastRefreshed: "Senast uppdaterad: {{{syncDate}}}",
    Message_Never: "aldrig",
    Message_Warning_IndicatorsHidden: "Observera att inställningen för att visa OKR-indikatorer på planen för närvarande är inaktiverad. För att visa indikatorn på planen måste du aktivera den i Vyinställning för Plan.",
    MessageDevOps_UpdateSettings: "Uppdatera {{{strings.__Plan}}}-inställningar",
    MessageError_DevOps_BrokenLink: "Kunde inte uppdatera data från Microsoft DevOps",
    MessageError_DevOps_BrokenLinkDescription: "Kunde inte uppdatera progress för {{{title}}}",
    MessageError_DevOps_UnableToUpdateProgress: "Kunde inte uppdatera länkad progress från Microsoft DevOps",
    MessageError_Project_BrokenLink: "Kunde inte uppdatera data från Microsoft Project",
    MessageError_Project_BrokenLinkDescription: "Kunde inte uppdatera progress för {{{title}}}",
    MessageError_Project_UnableToUpdateProgress: "Kunde inte uppdatera länkad progress från Microsoft Project",
    MessageProgress_CreatingItems: "Skapar VisPlan-objekt {{{start}}}/{{{end}}} ({{{itemKindName}}})",
    MessageProgress_Updating: "Uppdaterar progress",
    MessageProject_UpdateSettings: "Uppdatera inställningar för VisPlan",
    Migrated_DefaultKeyResultName: "Förvalt {{{strings.__KeyResult}}}",
    MoveActionToDp_ButtonCancel: "Avbryt",
    MoveActionToDp_ButtonOk: "OK",
    MoveActionToDp_ButtonProgress: "Flyttar...",
    MoveActionToDp_Kpi: "{{{strings.__KPI}}}",
    MoveActionToDp_Message: "Flytta {{{strings.__Action}}} till en annan {{{strings.__Ambition}}}",
    MoveActionToDp_SelectDP: "Välj {{{strings.__Ambition}}}",
    MoveActionToDp_SelectEffect: "Välj {{{strings.__Effect}}}",
    MoveActionToDp_SelectLOP: "Flyttad till {{{strings.__Capability}}}",
    MoveKeyResultToDp_ButtonCancel: "Avbryt",
    MoveKeyResultToDp_ButtonOk: "OK",
    MoveKeyResultToDp_ButtonProgress: "Flyttar...",
    MoveKeyResultToDp_Message: "Flytta {{{strings.__KeyResult}}} till en annan {{{strings.__Ambition}}}",
    MoveKeyResultToDp_SelectDP: "Välj {{{strings.__Ambition}}}",
    MoveKeyResultToDp_SelectLOP: "Flyttad till {{{strings.__Capability}}}",
    MSDevOpsConnection_ErrorGettingDevOpsList: "Kunde inte hämta listan över projekt",
    MSDevOpsConnection_ErrorGettingProgress: "Kunde inte hämta progressvärden",
    MSProjectConnection_ErrorGettingProgress: "Kunde inte hämta progressdata",
    MSProjectConnection_ErrorGettingProjectList: "Kunde inte hämta listan över projekt",
    MSProjectConnection_ErrorGettingProjectTasks: "Kunde inte hämta listan över projekt",
    MSProjectConnection_LabelProject: "Projekt",
    MSProjectConnection_LabelTask: "Uppgift (valfritt)",
    MSProjectConnection_LinkToSummary: "(Ingen - Projektsammanställning)",
    Navigation_ThisPlan: "Denna {{{strings.__Plan}}}",
    NavigationFrame_ButtonTooltip: "{{{strings.__Plan}}}-navigering",
    NavigationFrame_PanelTitle: "Navigering",
    NotificationButton_ShowDetails: "Visa detaljer",
    NotificationSidebar_Title: "Aviseringar",
    OkrFilter_ClearOwner: "Rensa",
    OkrFilter_Completed: "Slutförda",
    OkrFilter_CompletedTooltip: "Visa slutförda {{{strings.__Actions}}}",
    OkrFilter_Owner: "Tilldelad",
    Page_A0: "A0",
    Page_A1: "A1",
    Page_A2: "A2",
    Page_A3: "A3",
    Page_A4: "A4",
    Page_Auto: "Auto",
    PageSelectFileView_BrowseButton: "Ladda upp från min dator…",
    PageSelectFileView_UrlDescription: "Välj en redan exporterad (.json) fil att visa i visningsläge. Inga ändringar kan göras.",
    PageSelectFileView_UrlLabel: "Välj filens webbadress",
    PendingChange_Added: "skapa {{{title}}}",
    PendingChange_Assigned: "tilldela {{{title}}}",
    PendingChange_Deleted: "ta bort {{{title}}}",
    PendingChange_Unassigned: "frånta {{{title}}}",
    PendingChange_Updated: "ändra {{{title}}}",
    PercentComplete: "Färdigt:",
    PersonaBar_TitleWithCount: "{{{title}}} ({{{count}}})",
    Placeholder_Button: "Konfigurera",
    Placeholder_Description: "Matrisen måste vara ansluten till webbplatsens listor",
    Placeholder_IconText: "Konfigurera VisPlan-plan",
    Placeholer_ShowWhatsNewButton: "Visa vad som är nytt…",
    PlanConfiguration_AddingMobileNotSupportedDescription: "Att skapa en ny plan på mobilen är inte möjligt för tillfället, bara användning av befintliga planer. Använd skrivbordsversionen eller webbklienten för att skapa en ny plan.",
    PlanConfiguration_AddingMobileNotSupportedTItle: "Lägga till VisPlan",
    PlanConfiguration_Button: "Konfigurera",
    PlanConfiguration_NewPlanName: "Ny {{{strings.__Plan}}}",
    PlanName_ErrorGenerating: "Fel vid skapande av plan",
    PlannedPercentComplete: "Planerat:",
    Planner_DateConflict_Details: "{{{title}}} är inställd att använda datumen från dess associerade {{{strings.__Focus}}} och är länkad till Planner. Men datumen i Planner står i konflikt med {{{strings.__Focus}}} datumen: {{{properties}}}",
    Planner_DateConflict_Message: "Konfliktande datumändringar: {{{title}}}",
    Planner_DateConflict_OverwritePlannerMessage: "Uppdatera datum som är i konflikt (skriv över)",
    Planner_DateConflict_OverwritePlannerTitle: "Använd datum från VisPlan",
    Planner_DateConflict_UsePlannerMessage: "Uppdatera datum som är i konflikt (använd)",
    Planner_DateConflict_UsePlannerTitle: "Använd datum från Planner",
    PlannerError_ConfigurationActionError: "Fel vid genomförande av åtgärd i Planner {{{title}}}",
    PlannerError_UnableToGetBucket: "Kunde inte läsa in bucket {{{bucketId}}} från Microsoft Planner-planen {{{planId}}}",
    PlannerError_UnableToGetBuckets: "Kunde inte läsa in planens buckets från Microsoft Planner-planen {{{planId}}}",
    PlannerError_UnableToGetPlan: "Kunde inte läsa in Microsoft Planner-planen {{{planId}}}",
    PlannerError_UnableToGetTask: "Kunde inte läsa in uppgiften {{{taskId}}} från Microsoft Planner-planen {{{planId}}}",
    PlannerError_UpdatingData: "Fel vid uppdatering av data från Microsoft Planner.",
    PlannerMessage_CounterBuckets: "bucket(s)",
    PlannerMessage_CounterPlans: "plan(er)",
    PlannerMessage_CounterTasks: "uppgifter",
    PlannerMessage_CreatePlan: "[Microsoft Planner] Skapa plan {{{title}}}",
    PlannerMessage_CreateTask: "[Microsoft Planner] Skapa uppgift '{{{title}}}'",
    PlannerMessage_DeleteCheckbox: "[Microsoft Planner]: Radera även länkade Microsoft Planner-planer: <strong>{{{removalList}}}</strong>. OBS! Microsoft Planner-planer och dess uppgifter tas bort permanent.",
    PlannerMessage_DeleteList: "[Microsoft Planner]: Ta bort {{{removalList}}}",
    PlannerMessage_LinkToBucket: "[Microsoft Planner]: Länk till bucket '{{{title}}}'",
    PlannerMessage_LinkToPlan: "[Microsoft Planner]: Länk till plan '{{{title}}}'",
    PlannerMessage_LinkToTask: "[Microsoft Planner]: Länk till uppgift '{{{title}}}'",
    PlannerMessage_MoveBucket: "[Microsoft Planner] Ändra planens bucket: {{{title}}}",
    PlannerMessage_RenameBucket: "[Microsoft Planner] Byt namn på bucket: '{{{title}}}'",
    PlannerMessage_RenamePlan: "[Microsoft Planner] Byt namn på plan {{{title}}}",
    PlannerMessage_UpdateBucket: "[Microsoft Planner] Skapa bucket '{{{title}}}",
    PlannerMessage_UpdateLabels: "[Microsoft Planner] Uppdatera etiketter i {{{title}}}",
    PlannerMessage_UpdateSettings: "Uppdatera inställningar",
    PlannerMessage_UpdateTask: "[Microsoft Planner] Uppdatera uppgift {{{title}}}",
    PlannerName_Bucket: "Bucket",
    PlannerName_Plan: "Plan",
    PlannerNotification_ActionOnDisconnectedAmbition: "[Microsoft Planner] En {{{strings.__Action}}} som är länkad till en uppgift i Microsoft Planner har flyttats till en {{{strings.__Ambition}}} som inte finns i Microsoft Planner.",
    PlannerNotification_ActionOnDisconnectedAmbitionDetails: "{{{actionTitle}}} har en länk till en Uppgift i Microsoft Planner men som nu flyttats till {{{dpTitle}}} som inte finns i Microsoft Planner. Överväg om du vill flytta den till en annan {{{strings.__Ambition}}} som finns i Microsoft Planner eller ta bort den länkade uppgiften i Planner.",
    PlannerNotification_AuthorizationRequired: "[Microsoft Planner] Behörighet krävs",
    PlannerNotification_AuthorizationRequiredError: "Medgivande från användare eller administratör krävs för åtkomst till Microsoft Planner",
    PlannerNotification_BreakLinkTitle: "Bryt länk...",
    PlannerNotification_BreakPlannerLink: "Bryt länk till Microsoft Planner",
    PlannerNotification_BreakPlannerLinkDescription: "Tar bort länken {{{title}}} till Microsoft Planner.",
    PlannerNotification_BrokenBucketLink: "Kunde inte läsa in progressen för bucket {{{title}}}.",
    PlannerNotification_BrokenBucketLinkDetails: "Har det länkade objektet tagits bort? Om inte, prova att ladda om planen.",
    PlannerNotification_BrokenPlanLinkDetails: "{{{title}}} är kopplad till Microsoft Planner men planen kan inte hittas där. Vill du bryta länken eller återskapa den saknade planen i Microsoft Planner?",
    PlannerNotification_BrokenPlanLinkMessage: "[Microsoft Planner] {{{strings.__Capability}}} är länkad till en plan i Microsoft Planner som inte kan hittas.",
    PlannerNotification_BrokenTaskLink: "Kunde inte hämta länkad progress för Uppgiften {{{title}}}.",
    PlannerNotification_BrokenTaskLinkDetails: "Eventuellt har den länkade Uppgiften tagits bort i Microsoft Planner. Om inte, pröva att ladda om planen.",
    PlannerNotification_BucketNameDiffers: "[Microsoft Planner] Namnet för bucket är inte samma i Planner",
    PlannerNotification_BucketNameDiffersDescription: "Namnet för {{{title}}} (VisPlan) överensstämmer inte med bucketens namn '{{{bucketName}}}' i Microsoft Planner. Välj vilket namn som skall användas.",
    PlannerNotification_ButtonAllowEdit: "Tillåt redigering",
    PlannerNotification_ButtonAuthorize: "Verifiera...",
    PlannerNotification_ButtonCreate: "Skapa",
    PlannerNotification_ButtonCreating: "Skapar...",
    PlannerNotification_ButtonPlanner: "Planner",
    PlannerNotification_ButtonVisPlan: "VisPlan",
    PlannerNotification_CommandDeleteTask: "Ta bort uppgift...",
    PlannerNotification_CommandDeleteTaskDetails: "Ta bort uppgift som är länkad till {{{title}}} från den kopplade Microsoft Planner-planen. OBS! Denna åtgärd kan inte ångras.",
    PlannerNotification_CommandDeleteTaskTitle: "Ta bort uppgift",
    PlannerNotification_ConnectedAmbitionNotOnLine: "[Microsoft Planner] En {{{strings.__Ambition}}} som är länkad till en bucket är inte placerad på en {{{strings.__Capability}}} som är kopplad till Microsoft Planner.",
    PlannerNotification_ConnectedAmbitionNotOnLineDetails: "{{{dpName}}} är koppad till en Microsoft Planner bucket men inte placerad på en {{{strings.__Capability}}}. Överväg att flytta till en kopplad {{{strings.__Capability}}} eller ta bort bucket i Planner.",
    PlannerNotification_ConnectedAmbitionOnDisconnectedLine: "[Microsoft Planner] En {{{strings.__Ambition}}} som nu är länkad till en bucket i Planner har flyttats till {{{strings.__Capability}}} som inte är kopplad till Microsoft Planner.",
    PlannerNotification_ConnectedAmbitionOnDisconnectedLineDetails: "{{{strings.__Ambition}}} {{{dpName}}} är kopplad till en bucket i Microsoft Planner, men har nu flyttats till {{{strings.__Capability}}} {{{lopName}}} som inte har en plan i Microsoft Planner. Överväg att flytta den till en kopplad {{{strings.__Capability}}} eller ta bort bucket i Planner.",
    PlannerNotification_ConsentGrant: "[Microsoft Planner] Verifiera medgivande",
    PlannerNotification_ConsentRequiredDescription: "Med givande krävs {{{error}}}",
    PlannerNotification_CreateMissingBucket: "[Microsoft Planner] Skapa saknad Bucket i Planner",
    PlannerNotification_CreateMissingPlan: "[Microsoft Planner] Skapa saknad Plan i Planner",
    PlannerNotification_CreateMissingTask: "[Microsoft Planner] Skapa saknad Uppgift i Planner",
    PlannerNotification_CreateNewLinkedBucket: "Ny Bucket...",
    PlannerNotification_CreateNewLinkedBucketDescription: "Skapar en Bucket i Microsoft Planner länkad till {{{title}}}.",
    PlannerNotification_CreateNewLinkedBucketTitle: "Skapa ny länkad Microsoft Planner bucket",
    PlannerNotification_CreateNewLinkedPlan: "Ny Plan...",
    PlannerNotification_CreateNewLinkedPlanMessage: "Skapar en Microsoft Planner-plan länkad till {{{title}}}.",
    PlannerNotification_CreateNewLinkedPlanTitle: "Skapa ny länkad Microsoft Planner-plan",
    PlannerNotification_CreateNewLinkedTask: "Ny uppgift...",
    PlannerNotification_CreateNewLinkedTaskDescription: "Skapar en uppgift i Microsoft Planner länkad till {{{title}}}.",
    PlannerNotification_CreateNewLinkedTaskTitle: "Skapa ny länkad Microsoft Planner-uppgift",
    PlannerNotification_DeleteBucket: "[Microsoft Planner] Ta bort bucket",
    PlannerNotification_DeleteTask: "[Microsoft Planner] Ta bort uppgift",
    PlannerNotification_InvalidAmbitionDeleteBucket: "Ta bort bucket...",
    PlannerNotification_InvalidAmbitionDeleteBucketDetails: "Tar bort bucket som är länkad till {{{title}}} från den länkade Microsoft Planner-planen. OBS! Denna åtgärd är permanent och kan inte ångras.",
    PlannerNotification_InvalidAmbitionDeleteBucketTitle: "Ta bort Bucket",
    PlannerNotification_MessagePlanMismatch: "[Microsoft Planner] Underliggande plan överensstämmer inte",
    PlannerNotification_MessagePlanMismatchDetails: "VisPlan planens namn {{{title}}} överensstämmer inte med Planner-planens namn för Bucket '{{{bucketName}}}'.",
    PlannerNotification_PlanNameDifferent: "[Microsoft Planner] Planens namn i Planner är inte samma som i VisPlan",
    PlannerNotification_PlanNameDifferentDetails: "Namnet för {{{title}}} (VisPlan) överensstämmer inte med plannamnet '{{{planName}}}' (Planner). Välj vilket namn som skall användas.",
    PlannerNotification_RenamePlanMessage: "[Microsoft Planner] Ändra namn på Planen till {{{newName}}}",
    PlannerNotification_TestAuthorizationRequired: "Planen är inställd på endast läsläge för att skydda dess innehåll. Denna plan är länkad till Microsoft Planner; vänligen ge ditt medgivande till Planner för att kunna redigera planen.",
    PlannerNotification_TestFailed: "Planen är i endast läsläge på grund av ett serverproblem med Microsoft Planner. För närvarande kan inte anslutningen till Planner upprättas. Vänligen försök igen senare.",
    PlannerPlanWizard_CreateItems: "Skapa {{count}} VisPlan-objekt",
    PlannerPlanWizard_DeleteItems: "Ta bort {{count}} VisPlan-objekt",
    PlannerPlanWizard_NoGroupError: "Webbplatsen verkar inte vara ansluten till en Microsoft 365-grupp",
    PlannerPlanWizard_NoGroupErrorDetails: "Microsoft Planner fungerar endast med webbplatser som är anslutna till en Microsoft 365-grupp",
    PlannerPlanWizard_UpdateItems: "Uppdatera {{count}} VisPlan-objekt",
    PlannerPlanWizardStartPage_EnableLabelSync: "Använd VisPlan {{{strings.__Focuses}}} som etiketter i Planner",
    PlannerPlanWizardStartPage_OptionDP: "VisPlan {{{strings.__Ambition}}} ➔ Microsoft Planner-plan",
    PlannerPlanWizardStartPage_OptionLOP: "VisPlan {{{strings.__Capability}}} ➔ Microsoft Planner-plan",
    PlannerPlanWizardStartPage_OptionPlan: "VisPlan {{{strings.__Plan}}} ➔ Microsoft Planner-plan",
    PlanPermissions_Data: "Kan endast redigera data",
    PlanPermissions_Full: "Kan redigera grafik och data",
    PlanPermissions_LabelGuests: "Gästbehörigheter",
    PlanPermissions_LabelMembers: "Medlemsbehörigheter",
    PlanPermissions_View: "Enbart läsa",
    PlanProperties_DefaultView: "Standardvy",
    PlanProperties_GroupAbout: "Om",
    PlanProperties_GroupDisplay: "Skärm",
    PlanProperties_GroupLicense: "Licens",
    PlanProperties_Height: "Höjd",
    PlanProperties_ViewAlignment: "Inställningar för standardvyn",
    PlanSettings_BlockBackup: "Säkerhetskopia",
    PlanSettings_BlockBackupDescription: "Säkerhetskopiera {{{strings.__Plan}}} (exportera till fil)",
    PlanSettings_BlockDelete: "Ta bort",
    PlanSettings_BlockDeleteDescription: "Ta bort all data inklusive {{{strings.__Plan}}} och dess listor",
    PlanSettings_BlockManagement: "Hantering",
    PlanSettings_BlockUpgrade: "Uppgradera",
    PlanSettings_BlockUpgradeDescription: "Uppdatera {{{strings.__Plan}}} till senaste versionen",
    PlanSettings_ButtonDeletePlan: "Ta bort {{{strings.__Plan}}}",
    PlanSettings_ButtonDeletingPlan: "Tar bort...",
    PlanSettings_ButtonExportPlan: "Exportera {{{strings.__Plan}}}",
    PlanSettings_DeleteButtonOk: "Ta bort",
    PlanSettings_DeleteButtonOkBusy: "Tar bort...",
    PlanSettings_DeleteDialogSubText: "Detta kommer att ta bort och flytta all data inklusive {{{strings.__Plan}}} och dess listor till papperskorgen",
    PlanSettings_DeleteDialogTitle: "Ta bort {{{strings.__Plan}}}",
    PlanSettings_ErrorUnableToSaveStyle: "Kunde inte spara formatet",
    PlanSettings_ResetStylesDialogSubText: "Detta kommer att återställa formatanpassningar till standard.",
    PlanSettings_ResetStylesDialogTitle: "Återställ format",
    PlanSettings_SettingsBlockTitle: "Anpassningar och nyckelord",
    PlanSettings_ShowKpiIndicators: "Visa indikatorer för OKR",
    PlanSettings_ShowOwners: "Visa tilldelade",
    PlanSettings_ShowProgressIcons: "Visa ikoner för progresslänkar",
    PlanSettings_ShowProgressIndicators: "Visa progressindikatorer (Trafikljus)",
    PlanSettings_StyleBlock: "Format",
    PlanSettings_StyleBlockDescription: "Ersätt filen för standardformat style.xml",
    PlanSettings_styleReset: "Återställ",
    PlanSettings_styleReseting: "Återställer...",
    PlanSettings_StylesBlockEdit: "Ändra",
    PlanSettings_StylesBlockReset: "Återställ till standard",
    PlanSettings_StylesBlockSave: "Spara",
    PlanSettings_Title: "Inställningar för {{{strings.__Plan}}}",
    PlanSettings_UpgradeButton: "Uppgradera {{{strings.__Plan}}}",
    PlanSettings_UseFixedDate: "Lås 'Idag' till specifikt datum",
    PlanSettings_VisualFilterOpacity: "Transparens för visuellt filter",
    PlanSettingsCommand_Message: "Ändra Vyinställningar",
    PlanTemplate_ACTION: "Exempel-{{{strings.__Action}}}",
    PlanTemplate_ASSOC: "Detta är ett exempel på en 'motivering'",
    PlanTemplate_description_blank: "Starta din planeringsresa med en minimalistisk layout för att lägga till objekt och börja från grunden.",
    PlanTemplate_description_default: "Börja med en grundläggande 5x4 layout med 5 Förmågelinjer och 4 Ambitioner på varje.",
    PlanTemplate_description_ebike: "Utforska en omfattande operativ exempelplan för en produktlansering och se vad VisPlan kan åstadkomma.",
    PlanTemplate_description_windypower: "Titta närmare på ett exempel av en strategisk affärsplan för ett fiktivt vindkraftverksföretag.",
    PlanTemplate_DP: "{{{strings.__Ambition}}}",
    PlanTemplate_DP_Focus: "Exempel på {{{strings.__Ambition}}}",
    PlanTemplate_FOCUS: "{{{strings.__Focus}}}-exempel",
    PlanTemplate_FOCUS_Description: "<p>{{{strings.__Focuses}}} är till för att driva förändring och planera framsteg med prioriterade {{{strings.__Actions}}}. Varje {{{strings.__Plan}}} kan delas upp i flera sekventiella tidsbundna {{{strings.__Focus}}} och genomföras efter varandra allt eftersom de slutförs.</p><p>Tänk på att en {{{strings.__Focus}}} kan användas för att öka motivationen för deltagarna.</p>",
    PlanTemplate_KPI: "Exempel på {{{strings.__KPI}}}",
    PlanTemplate_KR: "Exempel på {{{strings.__KeyResult}}}",
    PlanTemplate_LOP: "{{{strings.__Capability}}}",
    PlanTemplate_LOP_Focus: "Exempel {{{strings.__Capability}}}",
    PlanTemplate_name_blank: "Minimal struktur",
    PlanTemplate_name_default: "Standard 5 x 4",
    PlanTemplate_name_ebike: "Exempelplan: E-Bike",
    PlanTemplate_name_windypower: "Exempelplan: WindyPower",
    PlanTemplate_POSITION: "{{{strings.__Position}}}",
    PlanTemplate_SO: "Exempel på {{{strings.__StrategicObjective}}}",
    PlanViewSettingsDialog_CustomStringsButton: "Anpassade begrepp...",
    PlanViewSettingsDialog_ErrorGreenOrder: "Värdet måste vara högre det Röda gränsvärdet",
    PlanViewSettingsDialog_ErrorOpacity: "Förväntat värde mellan 0 och 100%",
    PlanViewSettingsDialog_ErrorRange: "Värdet måste vara mellan -100% och +100%",
    PlanViewSettingsDialog_ErrorRedOrder: "Värdet måste vara lägre än det Gröna gränsvärdet",
    PlanViewSettingsDialog_ThresholdGreen: "Gränsvärde Grönt",
    PlanViewSettingsDialog_ThresholdRed: "Gränsvärde Rött",
    PlanViewSettingsDialog_Title: "Vyinställningar för {{{strings.__Plan}}}",
    PlanWizard_AnalyzingSite: "Förbereder för att generera planen...",
    PlanWizard_Back: "< Bakåt",
    PlanWizard_ClickToProceed: "Klicka på 'Konfigurera' för att fortsätta",
    PlanWizard_Close: "Stäng",
    PlanWizard_CompletedError: "Något gick fel. Växla vyn till de detaljerade inställningarna för att se felet.",
    PlanWizard_CompletedSuccess: "Konfigureringen är klar. Klicka på Stäng för att slutföra.",
    PlanWizard_Configure: "Konfigurera",
    PlanWizard_ConfigurePlan: "Konfigurera {{{strings.__Plan}}}",
    PlanWizard_ErrorAnalyzingSite: "Något gick fel, kunde inte konfigurera webbplatsen",
    PlanWIzard_Finish: "Slutför",
    PlanWizard_NameNotUnique: "Namnet används redan för en {{{strings.__Plan}}}",
    PlanWizard_Next: "Nästa >",
    PlanWizard_Processing: "Arbetar...",
    PlanWizard_SelectStorage: "Välj var du vill spara VisPlan",
    PlanWizard_UpToDate: "Allt är uppdaterat",
    PlanWizard_WizardTitle: "Guide för konfigurering av {{{strings.__Plan}}}",
    PlanWizardCreate_TabAssistant: "VisPlan Copilot",
    PlanWizardCreate_TabFile: "Skapa från en fil",
    PlanWizardCreate_TabFile_FileLabel: "Välj en fil att importera ifrån",
    PlanWizardCreate_TabFromFile: "Från fil",
    PlanWizardCreate_TabFromTemplate: "Från mall",
    PlanWizardCreate_TabNew: "Skapa ny",
    PlanWizardCreate_TabOrg: "Från organisationens mallar",
    PlanWizardCreatePage_PlanNameLabel: "{{{strings.__Plan}}}-namn",
    PlanWizardImport_ErrorImportingFile: "Fel vid import av data från fil.",
    PlanWizardImport_ErrorReadingFile: "Inläsningsfel. Kontrollera att du valt en fil som innehåller en exporterad {{{strings.__Plans}}} från VisPlan.",
    PlanWizardImport_Ignored: "Vissa objekt kommer att ignoreras.",
    PlanWizardImport_LinksIgnored: "Vissa externa länkar ({{count}}) kommer ignoreras.",
    PlanWizardImport_RemoveLinks: "Ta bort befintliga länkar för Progress (Förvalt)",
    PlanWizardImport_UsersIgnored: "Vissa externa användare kommer ignoreras: {{{users}}}.",
    PlanWizardImportPage_ButtonBrowse: "Bläddra...",
    PlanWizardImportPage_FileName: "Säkerhetskopia av {{{strings.__Plan}}}",
    PlanWizardImportPage_PlanName: "{{{strings.__Plan}}}-namn",
    PlanWizardMigratePage_PlanSelectorItem: "{{{name}}} (version: {{{planVersion}}}, id: {{{planId}}})",
    PlanWizardMigratePage_PlanSelectorLabel: "Plan att migrera",
    PlanWizardOpen_PlanNameLabel: "Flikens namn",
    PlanWizardOpen_TabFile: "Öppna fil i visningsläge",
    PlanWizardOpen_TabLive: "Återanslut till en plan",
    PlanWizardPageCreate_SubText: "Välj namn och mall för {{{strings.__Plan}}}",
    PlanWizardPageFileView_SubText: "Välj filvy",
    PlanWizardPageImport_SubText: "Välj filen för säkerhetskopia av {{{strings.__Plan}}}",
    PlanWizardPageMigrate_SubText: "Välj en Visto 2.0 plan att migrera",
    PlanWizardPageSelect_SubText: "Välj en befintlig {{{strings.__Plan}}}",
    PlanWizardPageSelection_SubText: "Valda objekt kommer att synkroniseras med Microsoft Planner",
    PlanWizardPageStart_SubText: "Välkommen till konfigureringsguiden",
    PlanWizardProgressPage_HideDetails: "Dölj detaljer",
    PlanWizardProgressPage_LongerThanExpected: "Åtgärden tar längre tid än väntat, håll ut!",
    PlanWizardSelectPage_SelectExistingDescription: "Återskapa en tidigare borttagen VisPlan-flik genom att återansluta till befintlig plandata. ",
    PlanWizardSelectPage_SelectExistingPlaceholder: "Välj en befintlig VisPlan",
    PlanWizardStartPage_CreateNewDescription: "Skapa ny VisPlan, från början eller från en fil",
    PlanWizardStartPage_CreateNewLabel: "Skapa ny VisPlan",
    PlanWizardStartPage_FileView: "Välj en fil för visningsläge",
    PlanWizardStartPage_Import: "Importera en VisPlan från en fil",
    PlanWizardStartPage_Migrate: "Migrera Visto 2.0 plan",
    PlanWizardStartPage_New: "Skapa en ny {{{strings.__Plan}}}",
    PlanWizardStartPage_OpenExistingDescription: "Öppna en befintlig VisPlan från en sajt eller fil",
    PlanWizardStartPage_OpenExistingLabel: "Öppna befintlig",
    PlanWizardStartPage_Select: "Välj en befintlig VisPlan",
    PlanWizardStartPage_Upgrade: "Uppgradera {{{strings.__Plan}}} till senaste versionen",
    PlanWizardStoragePage_CloudStorageDescription: "Planen kommer att lagras säkert i VisPlans molnlagring. Du kommer att kunna välja att dela planen med dina medarbetare.",
    PlanWizardStoragePage_CloudStorageLabel: "VisPlan Molnlagring",
    PlanWizardStoragePage_LocalStorageDescription: "Planen kommer att finnas i webbläsarens lokala lagring och är bara tillgänglig för dig. Du kan exportera den senare. ",
    PlanWizardStoragePage_LocalStorageLabel: "Din webbläsares lokala lagring",
    PlanWizardStoragePage_SharePointStorageDescription: "Planen kommer att lagras säkert i din organisations SharePoint-webbplats. Du kan välja vilken webbplats den skall sparas i. ",
    PlanWizardStoragePage_SharePointStorageLabel: "Din organisations SharePoint-webbplats",
    ProgressBlock_ProgressLabel: "Progress:",
    ProjectData_ProjectLinkName: "Länka till ett Microsoft Project '{{{projectName}}}'",
    ProjectData_ProjectTaskLinkName: "Länka till en aktivitet '{{{taskName}}}' i Microsoft Project '{{{projectName}}}'",
    ProjectLinkEditor_SelectProjectPlaceholder: "Välj ett projekt",
    ProjectLinkEditor_SelectProjectTaskPlaceholder: "Välj en aktivitet i projektet",
    ProjectNotification_AuthorizationRequired: "[Microsoft Project] Auktorisering krävs",
    ProjectNotification_AuthorizationRequiredError: "Användaren eller en administratör måste ge sitt medgivande för att ge åtkomst till Microsoft Project",
    ProjectNotification_BreakLinkTitle: "Bryt länk...",
    ProjectNotification_BreakProjectLink: "Bryt länk till Microsoft Project",
    ProjectNotification_BreakProjectLinkDescription: "Detta tar bort länken {{{title}}} till Microsoft Project.",
    ProjectNotification_ButtonAuthorize: "Auktoriserar...",
    ProjectNotification_ConsentGrant: "[Microsoft Project] Bevilja medgivande",
    ProjectNotification_ConsentRequiredDescription: "Medgivande krävs {{{error}}}",
    ProjectWizard_ProgressPageSubTitle: "Spara inställningarna",
    ProjectWizard_StartPage_EnableBoxTitle: "Aktivera integration med Microsoft Project",
    ProjectWizard_StartPage_UrlPlaceholder: "Ange adress till Microsoft Project Online-tjänsten.",
    ProjectWizard_StartPageSubTitle: "Aktivera integration med Microsoft Project",
    ProjectWizard_Title: "Microsoft Project-integration",
    PropertyPaneDescription: "Denna webbdel visar VisPlan {{{strings.__Plan}}}",
    PropertyPaneLicense_Valid: "Giltig",
    RefreshCommand_Message: "Uppdatera {{{syncDate}}}",
    RemovalMessage_Error: "Ett problem uppstod vid inläsning av {{{strings.__Plan}}}. Borttagning av fliken kommer endast ta bort fliken men inte radera eventuella datakällor.",
    RemovalMessage_PlanMissing: "{{{strings.__Plan}}} saknas. Borttagning av fliken kommer endast ta bort fliken.",
    RequestKey_ButtonClose: "Stäng",
    RequestKey_DialogTitle: "Hämta licensnyckel",
    RequestKey_DownloadFile: "Ladda ner fil för licensförfrågan",
    RequestKey_EmailHref: "mailto:info@visplan.com?subject=License%20Request&body=Site%20URL:%20${{{encodedSiteUrl}}}%0D%0APlan%20Id:%20${{{encodedPlanId}}}",
    RequestKey_EmailLink: "Öppna e-postprogrammet",
    RequestKey_FileName: "license-request.txt",
    RequestKey_PlanIdLabel: "Plan Id",
    RequestKey_Requesting: "Hämtar...",
    RequestKey_SiteUrlLabel: "Webbplatsens URL",
    RightArrow: "➔",
    SelectOption: "Välj...",
    ShapeAdded_Command: "Skapa {{{number}}} objekt",
    SharePointConfigurationService_ActionsLinkTitle: "{{{strings.__Actions}}}",
    SharePointConfigurationService_AddView: "Lägg till vy '{{{name}}}'",
    SharePointConfigurationService_ConfigureDefaultView: "Konfigurera standardvy",
    SharePointConfigurationService_CreateField: "Skapa fält '{{{fieldName}}}'",
    SharePointConfigurationService_CreateList: "Skapa lista '{{{listTitle}}}'",
    SharePointConfigurationService_CreateNavLinks: "Skapa navigeringslänk",
    SharePointConfigurationService_CreateNewItem: "Skapa ny {{{item}}}",
    SharePointConfigurationService_CreatePage: "Skapa sida '{{{title}}}'",
    SharePointConfigurationService_CreatePlan: "Skapa {{{strings.__Plan}}} '{{{name}}}'",
    SharePointConfigurationService_MatrixLinkTitle: "Matris",
    SharePointConfigurationService_MatrixPageTitle: "{{{planName}}} - Matris",
    SharePointConfigurationService_PlanLinkTitle: "{{{planName}}}",
    SharePointConfigurationService_PlanPageTitle: "{{{planName}}}",
    SharePointConfigurationService_SetPlanVersion: "Sätt version för {{{strings.__Plan}}} till {{{planVersion}}}",
    SharePointConfigurationService_UpdateField: "Uppdatera fält {{{fieldName}}}: {{{changedProps}}}",
    SharePointConfigurationService_UpdateList: "Uppdatera lista '{{{listTitle}}}'",
    SharePointConfigurationService_UpdatingListProperties: "Uppdatera listegenskaper: {{{changedProps}}}",
    SharePointConfigurationService_WebPartMissing: "Något gick fel under installationen. Kan inte hitta webbdelen för VisPlan.",
    SharepointExternalService_UnableToGetProgress: "Kunde ej hämta den länkade progressen för {{{title}}}.",
    SharepointExternalService_UnableToGetProgressDetails: "Har det länkade objektet tagits bort? Om inte, prova att uppdatera {{{strings.__Plan}}}.",
    SharePointNotification_ConsentGrant: "[SharePoint] Bevilja medgivande",
    ShowAssessment: "Visa Bedömning",
    ShowIndicators: "Visa OKR-indikatorer",
    ShowProgress: "Visa status",
    ShowValidations: "Visa {{{strings.__Validations}}}",
    Sidebar_AssocDuplicate: "Dubblett - {{{strings.__Validation}}} finns redan!",
    Sidebar_AssocMissing: "{{{strings.__Validation}}} har inget valt {{{strings.__StrategicObjective}}}",
    Sidebar_InplaceCancel: "Avbryt",
    Sidebar_InplaceEdit: "Redigera",
    Sidebar_InplaceSave: "Spara",
    SidebarActions_ActiveFocusGroup: "{{{title}}} ({{{count}}}) 🟊",
    SidebarActions_FocusGroup: "{{{title}}} ({{{count}}})",
    SidebarActions_NoFocusGroup: "Inget {{{strings.__Focus}}} ({{{count}}})",
    SidebarActionsCompleted_Title: "Slutförda ({{{count}}})",
    SidebarActionsInactive_Title: "Ej i {{{strings.__Focus}}} ({{{count}}})",
    SidebarAssoc_TooltipKRI: "KRI: {{{krName}}}",
    SidebarCheckList_DoneTotal: "{{{done}}}/{{{total}}}",
    SidebarGroup_AssigneeNoneTitle: "Ej tilldelade ({{count}})",
    SidebarGroup_AssigneeTitle: "{{title}} ({{count}})",
    SidebarGroup_Completed: "Slutförda ({{count}})",
    SidebarGroup_KpiNoneTitle: "Ej validerade med ett {{{strings.__KeyResult}}} ({{count}})",
    SidebarMenu_GroupByAssignee: "Tilldelad",
    SidebarMenu_GroupByButton: "Gruppera efter",
    SidebarMenu_GroupByFocus: "{{{strings.__Focus}}}",
    SidebarMenu_GroupByKpi: "{{{strings.__KeyResult}}}",
    SidebarMenu_GroupByNone: "Inget",
    SidebarMenuItem_Attachments: "Bifogade filer",
    SidebarMenuItem_Progress: "Progress",
    SidebarPositionSo_ProgressLabel: "Bedömd progress: {{{percents}}}",
    SidebarTimeFrameBlock_Text: "{{{viewStartDate}}} - {{{viewEndDate}}}",
    SidebarTimeFrameBlock_Title: "Aktivt {{{strings.__Focus}}}",
    SimpleProgressBar_Actual: "Utfall",
    SimpleProgressBar_Reference: "Planerat",
    SizingPage_Placeholder: "ÄNDRA SIDSTORLEK",
    StartDate: "Start:",
    StatusBar_SaveError: "Ej sparad",
    StatusBar_SavePending: "ändrad...",
    StatusBar_SavingNow: "sparar...",
    StatusBarZoom_CustomZoom: "Anpassad...",
    StatusBarZoom_FillPage: "Fyll sida",
    StatusBarZoom_FitPage: "Anpassa till sida",
    StatusBarZoom_Zoom: "Zooma",
    StatusText_FixedCopyAsOfDate: "Låst kopia från {{{date}}}",
    Stencil_Text: "Text",
    Stencil_Textbox: "Textruta",
    Stencil_TextboxContent: "<h1>Rubrik</h1><p>Skriv något här...</p>",
    Stencil_TextContent: "Text",
    Stencil_TooltipDp: "{{{strings.__Ambition}}} - Dra formen till planen för att lägga till en ny {{{strings.__Ambition}}}",
    Stencil_TooltipImage: "Bild - Dra formen till planen för att lägga till en ny bild",
    Stencil_TooltipLop: "{{{strings.__Capability}}} - Dra formen till planen för att lägga till en ny {{{strings.__Capability}}}",
    StrokeStyleButton_Title: "Kontur",
    SwatchPalette_Basic: "Standardfärger",
    SwatchPalette_Neutral: "Neutrala",
    SwatchPalette_Theme: "Temafärger",
    TabConfig_Configured: "{{{strings.__Plan}}} har redan konfigurerats",
    TabConfig_ConfiguredIconText: "Konfigurerad",
    TabConfig_Ready: "Klicka på Spara för att gå till {{{strings.__Plan}}}",
    TabConfig_ReadyIconText: "Färdig",
    TabRemove_DeleteDataCheckbox: "Ta bort {{{strings.__Plan}}} data och flytta till papperskorgen",
    TabRemove_Deleting: "Tar bort...",
    TabRemove_RemovaPlanDetails: "{{{strings.__Plan}}} är kopplad till en fil. Borttagningen av denna flik raderar ej filen.",
    TabRemove_RemovaPlanTitle: "Ta bort flik för {{{strings.__Plan}}}",
    TabRemove_Text: "Borttagning av fliken tar endast bort själva fliken i Microsoft Teams, data för {{{strings.__Plan}}} kommer inte att raderas. Du kan välja att radera data för {{{strings.__Plan}}}. Denna data kommer då att flyttas till papperskorgen i den SharePoint-webbplats som är kopplad för Teamet, där den kan återställas om så önskas. Om {{{strings.__Plan}}} har kopplade Microsoft Planner-planer kan du välja att även radera dessa. Planner planer och dess uppgifter kommer i så fall att raderas permanent.",
    TabRemove_Title: "Radera data för {{{strings.__Plan}}}",
    TextAlighComboBox_Title: "Textjustering",
    TextValue_None: "inget",
    TitleBlock_DescriptionLabel: "Beskrivning",
    TitleBlock_NameLabel: "Namn",
    Toggle_Off: "Av",
    Toggle_On: "På",
    TopMenuButton_BackgroundImage: "Bakgrund",
    TopMenuButton_Bold: "Fet",
    TopMenuButton_ClearFormatting: "Rensa Formatering",
    TopMenuButton_Copy: "Kopiera",
    TopMenuButton_Cut: "Klipp ut",
    TopMenuButton_Delete: "Radera",
    TopMenuButton_FormatPanel: "Format",
    TopMenuButton_Italic: "Kursiv",
    TopMenuButton_PageSize: "Sidstorlek",
    TopMenuButton_Paste: "Klistra in",
    TopMenuButton_Redo: "Gör om",
    TopMenuButton_Underline: "Understruken",
    TopMenuButton_Undo: "Ångra",
    TopToggleButton_Edit: "Redigera",
    TopToggleButton_TooltipEditByAnotherUser: "{{{strings.__Plan}}} redigeras just nu av {{{lockedToName}}}",
    TopToggleButton_TooltipReadOnly: "{{{strings.__Plan}}} går för närvarande inte att redigera.",
    TopToggleButton_View: "Redigering klar",
    Tutorial_close: "Stäng",
    Tutorial_done: "Färdig",
    Tutorial_drag_drop_headline: "Dra och släpp",
    Tutorial_drag_drop_text: "Lägg till objekt genom att dra-och-släppa",
    Tutorial_next_tip: "Nästa tips",
    Tutorial_save_headline: "Spara",
    Tutorial_save_text: "Avsluta redigeringsläget när du lagt till, flyttat eller justerat de objekt du önskar.",
    UnassignedItem: "(Inget)",
    UpgradePlanPlaceholder_Button: "Uppdatera",
    UpgradePlanPlaceholder_Description: "{{{strings.__Plan}}} behöver uppdateras för att fungera med den senaste versionen",
    UpgradePlanPlaceholder_IconText: "VisPlan har blivit bättre",
    Validate_DuplicateSourceLink: " {{{title}}} är redan länkad till {{{parentTitle}}} ",
    Validate_RequiredLinkAction: "Redigera {{itemKindName}}...",
    Validate_RequiredLinkLopDpMessage: "{{{itemKindName}}} är ej placerad på {{{linkKindName}}}",
    Validate_RequiredLinkLopDpMessageDetails: "{{{title}}} är inte placerad på en {{{linkKindName}}}. Växla till redigeringsläget och flytta {{{title}}} till en {{{linkKindName}}}.",
    Validate_RequiredLinkMessage: "[{{{strings.__Validation}}}] {{linkKindName}} är ej vald för {{itemKindName}}.",
    Validate_RequiredLinkMessageDetails: "Kopplad {{linkKindName}} krävs men är inte angiven för objektet {{{title}}}.",
    Validation_Limit_Detalis: "För den bästa upplevelsen rekommenderar vi att inte att ha fler än {{{limit}}} {{{itemKindName}}} objekt i samma plan. ",
    Validation_Limit_Message: "För många {{{itemKindName}}}-objekt ({{{counter}}})",
    VersionField_AppVersionLabel: "<p><img style='width:215px; height:57px' src={{logoImage}} /></p><p>Version {{version}}</p><p>Programmet skyddas av svensk och internationell upphovsrättslagstiftning samt av internationella konventioner.</p><p>VisPlan<sup>&reg;</sup> är ett registrerat varumärke tillhörande VisPlan AB.</p><p>VisPlan AB<br/><a href='https://www.visplan.com' target='_blank'>www.visplan.com</a></p>",
    VisPlanNotification_BreakLinkTitle: "Bryt länk...",
    VisPlanNotification_BreakVisPlanLink: "Bryt länk till VisPlan",
    VisPlanNotification_BreakVisPlanLinkDescription: "Tar bort länken {{{title}}} till underliggande VisPlan",
    VisPlanPicker_ErrorLoading: "Kunde inte hämta någon {{{strings.__Plan}}} att föreslå",
    VisPlanPicker_ItemId: "Objekt",
    VisPlanPicker_ItemType: "Objekttyp",
    VisPlanPicker_Plan: "{{{strings.__Plan}}}",
    VisPlanPicker_Site: "Team (webbplats)",
    VISTO_Action_AssignedTo: "Tilldelad till",
    VISTO_Action_Comments: "Kommentar",
    VISTO_Action_Created: "Skapad",
    VISTO_Action_Description: "Beskrivning",
    VISTO_Action_DP: "{{{strings.__Ambition}}}",
    VISTO_Action_Effect: "{{{strings.__Effect}}}",
    VISTO_Action_EndDate: "Förfallodatum",
    VISTO_Action_Focus: "{{{strings.__Focus}}}",
    VISTO_Action_Guid: "Guid",
    VISTO_Action_ItemId: "Id",
    VISTO_Action_KPI: "{{{strings.__KPI}}}",
    VISTO_Action_Links: "Länkar",
    VISTO_Action_LOP: "{{{strings.__Capability}}}",
    VISTO_Action_Modified: "Ändrat",
    VISTO_Action_Name: "{{{strings.__Action}}}",
    VISTO_Action_Name_Help: "Formulera en {{{strings.__Action}}} som en fullständig mening i aktiv form bestående av ett subjekt, predikat (verb) och objekt.",
    VISTO_Action_PercentComplete: "Procent färdigt",
    VISTO_Action_PercentComplete_Help: "Bedöm det aktuella Procent-färdigt för {{{strings.__Action}}}.",
    VISTO_Action_PlannedPercentComplete: "Planerat procent färdigt",
    VISTO_Action_SortOrder: "Sortering",
    VISTO_Action_SourceItem: "Länk till källa",
    VISTO_Action_SourceItem_Help: "Klistra in en länk till en källa att ärva progressdata från.",
    VISTO_Action_StartDate: "Startdatum",
    VISTO_Action_UseFocusDates: "Använd datum från {{{strings.__Focus}}}",
    VISTO_Assoc_Action: "{{{strings.__Action}}}",
    VISTO_Assoc_Confidence: "Påverkan",
    VISTO_Assoc_Confidence_Help: "Välj nivå av Påverkan för {{{strings.__Validation}}}",
    VISTO_Assoc_Created: "Skapad",
    VISTO_Assoc_Description: "Motivering",
    VISTO_Assoc_Description_Help: "Lägg till ett kort motivering för {{{strings.__Validation}}}.",
    VISTO_Assoc_DP: "VISTO_Assoc_DP",
    VISTO_Assoc_Guid: "Guid",
    VISTO_Assoc_ItemId: "Id",
    VISTO_Assoc_KeyResult: "{{{strings.__KeyResult}}}",
    VISTO_Assoc_KPI: "{{{strings.__KPI}}}",
    VISTO_Assoc_Links: "Länkar",
    VISTO_Assoc_Modified: "Ändrat",
    VISTO_Assoc_Name: "Namn",
    VISTO_Assoc_SO: "{{{strings.__StrategicObjective}}}",
    VISTO_Assoc_SO_Help: "Välj ett {{{strings.__StrategicObjective}}} som {{{strings.__Action}}} bidrar till att uppfylla.",
    VISTO_COA_Description: "{{{strings.__Plan}}} beskrivning",
    VISTO_DP_AssignedTo: "Tilldelad till",
    VISTO_DP_Comments: "Kommentar",
    VISTO_DP_Created: "Skapad",
    VISTO_DP_Description: "Beskrivning",
    VISTO_DP_EndDate: "Förfallodatum",
    VISTO_DP_Guid: "Guid",
    VISTO_DP_ItemId: "Id",
    VISTO_DP_Links: "Länkar",
    VISTO_DP_LOP: "{{{strings.__Capability}}}",
    VISTO_DP_Modified: "Ändrat",
    VISTO_DP_Name: "Namn",
    VISTO_DP_PercentComplete: "Procent färdigt",
    VISTO_DP_Phase: "{{{strings._Phase}}}",
    VISTO_DP_PlannedPercentComplete: "Planerat procent färdigt",
    VISTO_DP_SourceItem: "Länk till källa",
    VISTO_DP_SourceItem_Help: "Klistra in länk till källa för att ärva progressdata",
    VISTO_DP_StartDate: "Startdatum",
    VISTO_Effect_Created: "Skapad",
    VISTO_Effect_Description: "Beskrivning",
    VISTO_Effect_DP: "{{{strings.__Ambition}}}",
    VISTO_Effect_Guid: "Guid",
    VISTO_Effect_ItemId: "Id",
    VISTO_Effect_Links: "Länkar",
    VISTO_Effect_Modified: "Ändrat",
    VISTO_Effect_Name: "Namn",
    VISTO_Entity_Created: "Skapad",
    VISTO_Entity_Description: "Beskrivning",
    VISTO_Entity_Guid: "Guid",
    VISTO_Entity_ItemId: "Id",
    VISTO_Entity_Links: "Länkar",
    VISTO_Entity_Modified: "Ändrat",
    VISTO_Entity_Name: "Namn",
    VISTO_Focus_Active: "Aktiv",
    VISTO_Focus_AssignedTo: "Tilldelad till",
    VISTO_Focus_Created: "Skapad",
    VISTO_Focus_Description: "Beskrivning",
    VISTO_Focus_EndDate: "Förfallodatum",
    VISTO_Focus_Guid: "Guid",
    VISTO_Focus_ItemId: "Id",
    VISTO_Focus_Links: "Länkar",
    VISTO_Focus_Modified: "Ändrat",
    VISTO_Focus_Name: "Namn",
    VISTO_Focus_SortOrder: "Sortering",
    VISTO_Focus_StartDate: "Startdatum",
    VISTO_KR_AssignedTo: "Tilldelad till",
    VISTO_KR_Created: "Skapad",
    VISTO_KR_CurrentValue: "Aktuellt värde",
    VISTO_KR_DecimalPoints: "Decimaler",
    VISTO_KR_Description: "Beskrivning",
    VISTO_KR_DP: "{{{strings.__Ambition}}}",
    VISTO_KR_ExcelActuals: "VISTO_KR_ExcelActuals",
    VISTO_KR_ExcelTargets: "VISTO_KR_ExcelTargets",
    VISTO_KR_Guid: "Guid",
    VISTO_KR_Inverse: "Omvänd",
    VISTO_KR_ItemId: "Id",
    VISTO_KR_KR: "{{{strings.__KeyResult}}}",
    VISTO_KR_Links: "Länkar",
    VISTO_KR_Modified: "Ändrad",
    VISTO_KR_Name: "Namn",
    VISTO_KR_ShowOnDiagram: "Visa i {{{strings.__Plan}}}",
    VISTO_KR_SO: "SO",
    VISTO_KR_StartValue: "Startvärde",
    VISTO_KR_TargetValue: "Målvärde",
    VISTO_KR_Type: "Typ",
    VISTO_KR_Units: "Enhet",
    VISTO_KRV_Created: "Skapad",
    VISTO_KRV_CurrentValue: "Aktuellt värde",
    VISTO_KRV_Description: "Beskrivning",
    VISTO_KRV_Guid: "Guid",
    VISTO_KRV_ItemId: "Id",
    VISTO_KRV_KeyResult: "{{{strings.__KeyResult}}}",
    VISTO_KRV_Links: "Länkar",
    VISTO_KRV_Modified: "Ändrad",
    VISTO_KRV_Name: "Namn",
    VISTO_KRV_ValueDate: "Datum",
    VISTO_KRV_ValueKind: "Typ",
    VISTO_LOP_AssignedTo: "Tilldelad till",
    VISTO_LOP_Comments: "Kommentar",
    VISTO_LOP_Created: "Skapad",
    VISTO_LOP_Description: "Beskrivning",
    VISTO_LOP_EndDate: "Förfallodatum",
    VISTO_LOP_Guid: "Guid",
    VISTO_LOP_ItemId: "Id",
    VISTO_LOP_Links: "Länkar",
    VISTO_LOP_Modified: "Ändrat",
    VISTO_LOP_Name: "Namn",
    VISTO_LOP_PercentComplete: "Procent färdigt",
    VISTO_LOP_PlannedPercentComplete: "Planerat procent färdigt",
    VISTO_LOP_SourceItem: "Länk till källa",
    VISTO_LOP_SourceItem_Help: "Klistra in länk till källa för att ärva progressdata",
    VISTO_LOP_StartDate: "Startdatum",
    VISTO_Phase_Created: "Skapad",
    VISTO_Phase_Description: "Beskrivning",
    VISTO_Phase_Guid: "Guid",
    VISTO_Phase_ItemId: "Id",
    VISTO_Phase_Links: "Länkar",
    VISTO_Phase_Modified: "Ändrat",
    VISTO_Phase_Name: "Namn",
    VISTO_Plan_Created: "Skapad",
    VISTO_Plan_DefaultXml: "VisPlan DefaultXml (Ändra inte)",
    VISTO_Plan_ES_Description: "Beskrivning för {{{strings.__Position}}}",
    VISTO_Plan_ES_Name: "{{{strings.__Position}}}",
    VISTO_Plan_Id: "Plan-ID",
    VISTO_Plan_ItemId: "Id",
    VISTO_Plan_License: "Licens",
    VISTO_Plan_Links: "Länkar",
    VISTO_Plan_Modified: "Ändrat",
    VISTO_Plan_Name: "Plannamn",
    VISTO_Plan_NavigationLink: "Navigation Link",
    VISTO_Plan_Preview: "VISTO_Plan_Preview",
    VISTO_Plan_Settings: "Settings",
    VISTO_Plan_Version: "Planversion (Ändra inte)",
    VISTO_Plan_XmlData: "VisPlan XmlData (Ändra inte)",
    VISTO_SO_AssignedTo: "Tilldelad till",
    VISTO_SO_Created: "Skapad",
    VISTO_SO_Description: "Beskrivning",
    VISTO_SO_Entity: "Ansvarig",
    VISTO_SO_Guid: "Guid",
    VISTO_SO_ItemId: "Id",
    VISTO_SO_Links: "Länk",
    VISTO_SO_Modified: "Ändrat",
    VISTO_SO_Name: "Namn",
    VISTO_SO_SortOrder: "Sortering",
    VistoActionItem_ViewMyActionsTitle: "Mina {{{strings.__Actions}}}",
    VistoKind_Action_list: "{{{planName}}} - {{{strings.__Actions}}}",
    VistoKind_Assoc_list: "{{{planName}}} - {{{strings.__Validations}}}",
    VistoKind_DP_list: "{{{planName}}} - {{{strings.__Ambition}}}",
    VistoKind_Effect_list: "{{{planName}}} - {{{strings.__Effects}}}",
    VistoKind_Entity_list: "{{{planName}}} - Ansvariga",
    VistoKind_Focus_list: "{{{planName}}} - {{{strings.__Focuses}}}",
    VistoKind_KR_list: "{{{planName}}} - {{{strings.__KeyResults}}}",
    VistoKind_KRV_list: "{{{planName}}} - {{{strings.__KeyResult}}}-värden",
    VistoKind_LOP_list: "{{{planName}}} - {{{strings.__Capabilities}}}",
    VistoKind_Phase_list: "{{{planName}}} - {{{strings._Phase}}}",
    VistoKind_Plan_list: "VisPlan-{{{strings.__Plans}}}",
    VistoKind_SO_list: "{{{planName}}} - {{{strings.__StrategicObjectives}}}",
    VistoKRVItem_Actual: "(0) {{{strings.__Actual}}}",
    VistoKRVItem_Target: "(1) {{{strings.__Target}}}",
    VisualFilter_AssignedTo: "Tilldelad: {{{userList}}}",
    VisualFilter_None: "Inget visuellt filter valt",
    VisualFilter_Title: "Visuellt filter",
    Warning_ServerOverloaded: "Servern är överbelastad, försöker igen om {{wait}} sekunder.",
    WarningMessage_DuplicateGuid: "Upptäckte dubbletter av objekt-id",
    WarningMessage_DuplicateGuidDetails: "Det finns fler än en {{{vistoKindName}}} som har samma id {{{guid}}}. Detta kan orsaka konflikter. Kontrollera SharePoint-listan.",
    WarningMessage_MissingGuid: "Upptäckte saknade id",
    WarningMessage_MissingGuidDetails: "Upptäckte {{{vistoKindName}}} utan unika id. Uppdaterar dessa till automatgenererade id",
    WebAuthScope_DefaultChannel: "Webbläsare",
    WebAuthScope_DefaultTeam: "Webbåtkomst",
    WidthButton_Title: "Bredd"
  };
});