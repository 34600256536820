import * as React from 'react';
import { Stack, INavLinkGroup, Nav, INavLink, Dropdown, IDropdownOption, IconButton } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { AppContext } from 'services/AppContext';

interface ITopicKey {
  whatsnew;
  index;
  LOP;
  DP;
  POSITION;
  Action;
  SO;
  Focus;
  Reporting;
  Matrix;
}


const topics_en_us: ITopicKey = {
  whatsnew: require('advisory/en/docs/whatsnew.md'),
  index: require('advisory/en/docs/index.md'),
  LOP: require('advisory/en/docs/LOP.md'),
  DP: require('advisory/en/docs/DP.md'),
  POSITION: require('advisory/en/docs/POSITION.md'),
  Action: require('advisory/en/docs/Action.md'),
  SO: require('advisory/en/docs/SO.md'),
  Focus: require('advisory/en/docs/Focus.md'),
  Reporting: require('advisory/en/docs/Reporting.md'),
  Matrix: require('advisory/en/docs/Matrix.md'),
};

const topics_sv_se: ITopicKey = {
  whatsnew: require('advisory/se/docs/whatsnew.md'),
  index: require('advisory/se/docs/index.md'),
  LOP: require('advisory/se/docs/LOP.md'),
  DP: require('advisory/se/docs/DP.md'),
  POSITION: require('advisory/se/docs/POSITION.md'),
  Action: require('advisory/se/docs/Action.md'),
  SO: require('advisory/se/docs/SO.md'),
  Focus: require('advisory/se/docs/Focus.md'),
  Reporting: require('advisory/se/docs/Reporting.md'),
  Matrix: require('advisory/se/docs/Matrix.md'),
};

export type TopicKey = keyof ITopicKey;

const toc_en_us = require('advisory/en/toc.yml') as any;
const toc_sv_se = require('advisory/se/toc.yml') as any;

const toc_sidebar_en_us = require('advisory/en/toc_sidebar.yml') as any;
const toc_sidebar_sv_se = require('advisory/se/toc_sidebar.yml') as any;

const contentSet = {
  'en-us': {
    toc: toc_en_us,
    toc_sidebar: toc_sidebar_en_us,
    topics: topics_en_us
  },
  'sv-se': {
    toc: toc_sv_se,
    toc_sidebar: toc_sidebar_sv_se,
    topics: topics_sv_se
  }
};

const isPhone = window.matchMedia('(max-width: 640px)').matches;

export function Advisory(props: {
  topic: TopicKey;
  style?: any;
  isSidebar?: boolean;
}) {

  const appContext = React.useContext(AppContext);

  const content = contentSet[TextService.uiLanguage];

  const topics = content.topics;
  const toc = props.isSidebar ? content.toc_sidebar : content.toc;

  const [selectedTopic, setSelectedTopic] = React.useState(props.topic);

  const createConfig = (e) => {
    const name = Object.keys(e)[0];
    const key = (e[name] as string).replace(/.md$/, '');
    return { name, key, url: '#' };
  };

  const links = toc.nav.map(e => createConfig(e));

  const options: IDropdownOption[] = links.map(l => ({ key: l.key, text: l.name }));

  const navLinkGroups: INavLinkGroup[] = [{ links }];

  function onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    ev.preventDefault();
    setSelectedTopic(item.key as TopicKey);
  }

  const onClose = () => {
    if (props.isSidebar && appContext) {
      appContext.setPropertyBag({ showAdvisory: false });
    }
  }

  return (
    <Stack style={props.style} horizontal grow>
      {!isPhone && <Nav styles={{ root: { paddingTop: 16 } }} groups={navLinkGroups} onLinkClick={onLinkClick} selectedKey={selectedTopic} />}
      <Stack grow tokens={{ childrenGap: 'm', padding: isPhone ? null : '0 0 0 m' }} >
        {isPhone && <Dropdown selectedKey={selectedTopic} onChange={(_, val) => setSelectedTopic(val.key as TopicKey)} options={options} />}
        <div data-is-scrollable='true' style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: topics[selectedTopic] }}></div>
      </Stack>
      {props.isSidebar && <IconButton iconProps={{ iconName: 'ChromeClose' }} onClick={onClose} />}
    </Stack>
  );
}
