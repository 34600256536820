import { Stack, TooltipHost, IconButton, Text } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import { IAssistatCard } from 'frames/IAssistatCard';
import React from 'react';
import { FileService } from 'services/FileService';
import { TextService } from 'services/TextService';
import { UrlService } from 'shared/urlService';

export const AssistantSidebarHeader = (props: {
  cards: IAssistatCard[];
  onClearMessages: () => void;
}) => {

  const exportLogs = () => {
    FileService.export('debug.log', 'application/json', JSON.stringify(props.cards, null, 2));
  }

  return (
    <Stack grow horizontal tokens={{ padding: '0 m', childrenGap: 'm' }}>
      <Stack><Text variant='xLarge'>{TextService.format(strings.AssistantToolbar_Caption)}</Text></Stack>
      <TooltipHost content={TextService.format(strings.AssistantToolbar_Reset)} >
        <IconButton iconProps={{ iconName: 'Delete' }} onClick={props.onClearMessages} />
      </TooltipHost>
      {UrlService.isDev && <TooltipHost content={TextService.format(strings.AssistantToolbar_ExportLogs)} >
        <IconButton iconProps={{ iconName: 'PageSolid' }} onClick={exportLogs} />
      </TooltipHost>}
    </Stack>
  );
}
